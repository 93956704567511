import React from 'react';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { GridsetColumn, GridsetAllColumns } from './GridsetAllColumns';


export const GridsetSpec = () => (
  <Box paddingX={theme.space.viewportPaddingX}>
    <GridsetAllColumns bg="app.bg.dark" marginY={8} />
    <Gridset bg="app.bg.dark" marginY={1}>
      <GridsetColumn gridColumn="a0b0 / a1" />
      <GridsetColumn gridColumn="a1 / a2" />
      <GridsetColumn gridColumn="a2 / a3b2" />
      <GridsetColumn gridColumn="a3b2 / a4" />
      <GridsetColumn gridColumn="a4 / a5" />
      <GridsetColumn gridColumn="a5 / a6b4" />
    </Gridset>
    <Gridset bg="app.bg.dark" marginY={1}>
      <GridsetColumn gridColumn="a0b0 / b1" />
      <GridsetColumn gridColumn="b1 / a3b2" />
      <GridsetColumn gridColumn="a3b2 / b3" />
      <GridsetColumn gridColumn="b3 / a6b4" />
    </Gridset>
    <Gridset bg="app.bg.dark" marginY={8}>
      <GridsetColumn bg="dangerPalette.silverLake.light" gridColumn="a05" />
      <GridsetColumn bg="dangerPalette.silverLake.light" gridColumn="a1 / a4" />
      <GridsetColumn bg="dangerPalette.silverLake.light" gridColumn="a5 / a55" />
    </Gridset>
  </Box>
);
