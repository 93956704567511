import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { CardExpandable } from 'components/CardExpandable';
import { Box } from 'components/Layout';


export const Persona = ({ avatarSrc, ...props }) => (
  <Box
    gridColumn={{
      base: 'span 12',
      [LAYOUT_SWITCH]: 'span 6',
      xl: 'span 4',
    }}
    gridRow={{
      base: null,
      [LAYOUT_SWITCH]: 2,
      xl: 1,
    }}
    paddingX={4}
    paddingY={{
      base: 4,
      [LAYOUT_SWITCH]: 16
    }}
    position="relative"
  >
    <CardExpandable
      imageSize="2em"
      imageSrc={ avatarSrc }
      imageSVGViewBox="0 0 310 310"
      isSVG={true}
      left={4}
      minHeight={{
        base: null,
        [LAYOUT_SWITCH]: '14rem',
        lg: '12rem',
      }}
      position={{ [LAYOUT_SWITCH]: 'absolute' }}
      right={4}
      toggleLabel="Goals & Pain Points"
      {...props}
    />
  </Box>
);

Persona.propTypes = {
  avatarSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
