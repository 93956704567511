import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { PseudoBox } from 'components/Layout';


export const LinkExternal = ({ href, to, ...props }) => (
  <PseudoBox
    _active={{
      color: 'linkExternal.colorActive',
    }}
    _hover={{
      color: 'linkExternal.colorHover',
    }}
    as={to ? Link : !! href ? 'a' : 'button'}
    boxShadow="inset transparent 0 0 0 0"
    color="linkExternal.color"
    display="inline-block"
    fontSize="1em" // uses `em` instead of `rem` to match parent's font size
    href={href}
    letterSpacing="tight"
    lineHeight="base"
    paddingX="0.05em"
    position="relative"
    textDecoration="underline"
    to={to}
    transition="all 0.2s ease-out"
    {...props}
  />
);

LinkExternal.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
};
