import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'lib/router/PrivateRoute';
import { routes } from 'lib/router/routes';

import { Home } from 'pages/Home';
import { Preact } from 'pages/Portfolio/Preact';
import { Rafter } from 'pages/Portfolio/Rafter';
import { Spotify } from 'pages/Portfolio/Spotify';
import { StrongDM } from 'pages/Portfolio/StrongDM';
import { Styleguide } from 'pages/Styleguide';


export const SwitchRoutes = ({ loggedIn }) => (
  <Switch>
    <Route exact path={ routes.Home }>
      <Home loggedIn={loggedIn} />
    </Route>
    <PrivateRoute exact loggedIn={loggedIn} path={ routes.Preact }>
      <Preact />
    </PrivateRoute>
    <PrivateRoute exact loggedIn={loggedIn} path={ routes.Rafter }>
      <Rafter />
    </PrivateRoute>
    <PrivateRoute exact loggedIn={loggedIn} path={ routes.Spotify }>
      <Spotify />
    </PrivateRoute>
    <PrivateRoute exact loggedIn={loggedIn} path={ routes.StrongDM }>
      <StrongDM />
    </PrivateRoute>
    <PrivateRoute exact loggedIn={loggedIn} path={ routes.Styleguide }>
      <Styleguide />
    </PrivateRoute>
    <Redirect to={ routes.Home } />
  </Switch>
);


SwitchRoutes.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};
