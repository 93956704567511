import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { ArticleBody } from 'components/ArticleBody';
import { Card } from 'components/Card';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { LinkExternal } from 'components/LinkExternal';
import { LinkInternal } from 'components/LinkInternal';
import { SectionHeader } from 'components/SectionHeader';


const layoutSwitch = 'md';

const cardProps = {
  borderRadius: 'md',
  gridColumn: {
    base: 'span 12',
    [layoutSwitch]: 'span 6',
  },
  marginX: {
    base: 0,
    [layoutSwitch]: 'carouselCardPaddingX'
  },
  marginY: {
    base: 2,
    [layoutSwitch]: 0,
  },
  padding: 6,
};

export const ProjectOutcomes = ({ spotifyInternalRoutes }) => (<>
  <ArticleBody>
    <SectionDivider id={spotifyInternalRoutes.outcomes} />
    <SectionHeader alignItems="flex-start" colorDecoration="scheme.secondary.dark" title="Outcomes" />

    <ol>
      <li>Surveyed Beanstalk users with the System Usability Survey (SUS).</li>
      <li>Scripted user testing facilitated by UX Researchers.</li>
      <li>Gathered feedback while in progress. I targeted 2 hours per 2-week sprint with users matching our <LinkInternal href={`#${spotifyInternalRoutes.audience}`}>personas</LinkInternal>, including both active and inactive Beanstalk users.</li>
      <li>Design hypotheses evaluated by their specific success criteria.</li>
    </ol>
    <p>While our team was experienced measuring product and user success quantitatively, internal tool development required a more qualitative approach given the smaller user base.</p>
  </ArticleBody>

  <SectionDivider height="half" />

  <Gridset
    paddingX={theme.space.viewportPaddingX}
    position="relative"
    zIndex="float"
  >
    <Box
      bg="scheme.tertiary.base"
      height={{
        base: 0,
        [layoutSwitch]: 24,
      }}
      left={theme.space.viewportPaddingX}
      position="absolute"
      right={theme.space.viewportPaddingX}
      top="50%"
      transform="translate3d(0, -66%, 0)"
      zIndex="hide"
    />
    <Card
      {...cardProps}
      className="typographyEnabled"
      title="System Usability Survey (SUS)"
    >
      <p>This project resulted in a 17.5 point increase on the 0-100 <LinkExternal href="https://www.usability.gov/how-to-and-tools/methods/system-usability-scale.html">System Usability Scale</LinkExternal>.</p>
      <p>The SUS consists of 10 standard questions, broadly applicable across a variety of products and services, that users answer with a range of <q>strongly agree</q> to <q>strongly disagree</q>.</p>
      <p>Change was measured against an SUS benchmark established with active users at the beginning of the quarter.</p>
    </Card>

    <Card
      {...cardProps}
      className="typographyEnabled"
      title="Scripted User Testing"
    >
      <p>Spotify’s UX Researchers conducted formal user testing sessions with potential users behind one-way glass, using a clickable prototype I produced.</p>
      <p>The script generated insights for Interpretation, Intuitiveness, and Usefulness.</p>
      <p>In addition to the compiled report and recordings, I brought all members of the team to observe in real time, as the benefits of research truly take hold when the team has internalized how users experience the product.</p>
    </Card>
  </Gridset>
</>);

ProjectOutcomes.propTypes = {
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
