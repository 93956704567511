import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { OverviewItem } from 'components/OverviewItem';
import {
  Nbsp,
  BodyAlt,
} from 'components/Typography';
import { AudiencePersonas } from './AudiencePersonas';


export const Audience = ({ bg }) => (
  <Box bg={bg} id="spotifyAudience">
    <Gridset
      alignItems="center"
      color="app.bg.base"
      paddingBottom={{
        base: 8,
        [LAYOUT_SWITCH]: 32,
        xl: 0,
      }}
      paddingTop={{
        base: 16,
        [LAYOUT_SWITCH]: 0,
      }}
      paddingX={theme.space.viewportPaddingX}
    >
      <OverviewItem
        color="scheme.accent.base"
        gridColumn={{
          base: 'span 12',
          [LAYOUT_SWITCH]: 'a05 / a55',
          xl: 'span 4',
        }}
        gridRow={1}
        header="Users & Personas"
        title={ <span>Multidisciplinary product<Nbsp/>teams.</span> }
        zIndex={1}
      >
        <BodyAlt as="div">PMs, designers, data scientists, engineers used our tools to devise and run experiments.</BodyAlt>
        <BodyAlt as="div" paddingTop={4}>We split these users into two personas, <Box as="strong" color="portfolio.spotify.persona.maven.base">Data Mavens</Box> and <Box as="strong" color="portfolio.spotify.persona.maker.base">Product Makers</Box>, keyed by how they worked with data and how they used the ouputs.</BodyAlt>
      </OverviewItem>
      <AudiencePersonas />
    </Gridset>
  </Box>
);

Audience.propTypes = {
  bg: PropTypes.string,
};
