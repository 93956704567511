import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { Flex } from 'components/Layout';


const BoxShadow = ({ shadow }) => (
  <Flex
    alignItems="center"
    bg="white"
    boxShadow={shadow}
    children={shadow}
    flexGrow={1}
    flexShrink={0}
    justifyContent="center"
    marginX={4}
    minHeight={24}
    minWidth={12}
  />
);


export const Shadows = () => (
  <Flex
    bg="app.bg.dark"
    justifyContent="space-between"
    paddingX={theme.space.viewportPaddingX}
    paddingY="gutterOutside"
  >
    <BoxShadow shadow={0} />
    <BoxShadow shadow={1} />
    <BoxShadow shadow={2} />
    <BoxShadow shadow={3} />
    <BoxShadow shadow={4} />
    <BoxShadow shadow={5} />
  </Flex>
);

BoxShadow.propTypes = {
  shadow: PropTypes.number.isRequired,
};
