import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Spring } from 'react-spring/renderprops';
import { Icon } from '@chakra-ui/core';
import { Box } from 'components/Layout';


const Arrow = ({ ...props }) => (
  <Icon
    marginTop={4}
    name="arrow-down"
    size="2rem"
    {...props}
  />
);

export const ArrowRow = ({ count }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  return (
    <Box
      display={{
        base: 'none',
        lg: 'block'
      }}
    >
      <Box
        bottom="80%"
        position="absolute"
        ref={intersectionObserverRef}
      />
      <Spring
        config={{ friction: 10 }}
        to={{
          opacity: !! inView ? 1 : 0,
          transform: !! inView ? 'scale(1) translate3d(-50%, -100%, 0)' : 'scale(0.95) translate3d(-50%, -150%, 0)',
          visibility: !! inView ? 'visible' : 'hidden',
        }}
      >
        { springStyle => (
          <Box
            left="50%"
            position="absolute"
            style={springStyle}
            top="0"
            zIndex="float"
          >
            { count >= 2 &&
              <Arrow
                color="card.text"
                opacity={ count > 2 && 0.25 }
                transform="scale(0.6) translate3d(-25vw, 20%, 0) rotate(45deg)"
              />
            }
            { !! (count % 2) &&
              <Arrow color="scheme.secondary.base" /> }
            { count >= 2 &&
              <Arrow
                color="card.text"
                opacity={ count > 2 && 0.25 }
                transform="scale(0.6) translate3d(25vw, 20%, 0) rotate(-45deg)"
              />
            }
          </Box>
        )}
      </Spring>
    </Box>
  );
};

ArrowRow.propTypes = {
  count: PropTypes.number.isRequired,
};
