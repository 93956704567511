import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { SectionDivider } from 'components/Divider';
import {
  GalleryDivider,
  GallerySection,
} from 'components/GallerySection';
import { Box } from 'components/Layout';
import { PaletteBar } from 'components/PaletteBar';
import { SectionHeader } from 'components/SectionHeader';


const assetPath = '/assets/portfolio/spotify/';

const gallery = {
  deliverables: [
    {
      alt: 'Beanstalk behavioral insights.',
      modalSrc: `${assetPath}beanstalk-alt-search.png`,
      src: `${assetPath}beanstalk-alt-search-thumb.png`,
    },
    // {
    //   alt: 'Beanstalk behavioral insights.',
    //   modalSrc: `${assetPath}beanstalk-scenarioLong.png`,
    //   src: `${assetPath}beanstalk-scenarioLong-thumb.png`,
    // },
    {
      alt: 'Beanstalk behavioral insight detail view.',
      modalSrc: `${assetPath}beanstalk-detailsPage.png`,
      src: `${assetPath}beanstalk-detailsPage-thumb.png`,
    },
    // {
    //   alt: 'Beanstalk behavioral insights, hovering a row.',
    //   modalSrc: `${assetPath}beanstalk-scenario[hover].png`,
    //   src: `${assetPath}beanstalk-scenario[hover]-thumb.png`,
    // },
    // {
    //   alt: 'Filtering Beanstalk results by how common the behaviors are.',
    //   modalSrc: `${assetPath}beanstalk-filterHowCommon.png`,
    //   src: `${assetPath}beanstalk-filterHowCommon-thumb.png`,
    // },
    // {
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters.',
    //   modalSrc: `${assetPath}beanstalk-alt.png`,
    //   src: `${assetPath}beanstalk-alt-thumb.png`,
    // },
    // {
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying share flow.',
    //   modalSrc: `${assetPath}beanstalk-alt-filtersExpanded.png`,
    //   src: `${assetPath}beanstalk-alt-filtersExpanded-thumb.png`,
    // },{
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying tag filter.',
    //   modalSrc: `${assetPath}beanstalk-alt-filterTag.png`,
    //   src: `${assetPath}beanstalk-alt-filterTag-thumb.png`,
    // },
    // {
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying empty state.',
    //   modalSrc: `${assetPath}beanstalk-alt-emptyState.png`,
    //   src: `${assetPath}beanstalk-alt-emptyState-thumb.png`,
    // },{
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying share flow.',
    //   modalSrc: `${assetPath}beanstalk-alt-share.png`,
    //   src: `${assetPath}beanstalk-alt-share-thumb.png`,
    // },
    {
      alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying instructional tooltip.',
      modalSrc: `${assetPath}beanstalk-alt-effectTooltip.png`,
      src: `${assetPath}beanstalk-alt-effectTooltip-thumb.png`,
    },
    // {
    //   alt: 'Beanstalk iteration following this project focused on improving capability and usability of filters. Displaying instructional tooltip.',
    //   modalSrc: `${assetPath}beanstalk-alt-commonTooltip.png`,
    //   src: `${assetPath}beanstalk-alt-commonTooltip-thumb.png`,
    // },
    // {
    //   alt: 'Alternate view of behavior details.',
    //   modalSrc: `${assetPath}beanstalk-detailsPage-alt.png`,
    //   src: `${assetPath}beanstalk-detailsPage-alt-thumb.png`,
    // },
    // {
    //   alt: 'Alternate view of behavior details, with path to export to BigQuery.',
    //   modalSrc: `${assetPath}beanstalk-alt-exportToBigQuery.png`,
    //   src: `${assetPath}beanstalk-alt-exportToBigQuery-thumb.png`,
    // },
    {
      alt: 'Spotify in-app messages for rapidly experimenting on behavior influence.',
      modalSrc: `${assetPath}messaging-experiments.png`,
      src: `${assetPath}messaging-experiments-thumb.png`,
    },
  ],
  artifacts: [
    {
      alt: 'Accessible color scale for visually indicating the estimated effect of a behavior.',
      modalSrc: `${assetPath}beanstalk-estimatedEffectAccessibility.png`,
      src: `${assetPath}beanstalk-estimatedEffectAccessibility-thumb.png`,
    },
    {
      alt: 'Sketch.app artboards overview for Beanstalk UI.',
      modalSrc: `${assetPath}beanstalk-sketch.png`,
      src: `${assetPath}beanstalk-sketch-thumb.png`,
    },
    {
      alt: 'Design patterns for web dialog boxes.',
      modalSrc: `${assetPath}tape-dialog.png`,
      src: `${assetPath}tape-dialog-thumb.png`,
    },
    {
      alt: 'Rubrik for assessing experiment results.',
      modalSrc: `${assetPath}experiment-assessingResults.png`,
      src: `${assetPath}experiment-assessingResults-thumb.png`,
    },
    {
      alt: 'Google Gallery of iterative compositions, shared with users for feedback.',
      modalSrc: `${assetPath}googleGallery-projects.png`,
      src: `${assetPath}googleGallery-projects-thumb.png`,
    },
    {
      alt: 'Feedback intake and review in Google Sheets.',
      modalSrc: `${assetPath}googleSheets-feedback.png`,
      src: `${assetPath}googleSheets-feedback-thumb.png`,
    },
    {
      alt: 'Crazy 8s sketches generated by the team.',
      modalSrc: `${assetPath}crazy8s.png`,
      src: `${assetPath}crazy8s-thumb.png`,
    },
    // {
    //   alt: 'Early port of Preact’s styleguide to Spotify Beanstalk',
    //   modalSrc: `${assetPath}styleguide-preact-port.png`,
    //   src: `${assetPath}styleguide-preact-port-thumb.png`,
    // },
  ],
  // previousIteration: [
  //   {
  //     alt: 'Beanstalk ML UI',
  //     modalSrc: `${assetPath}beanstalk-previousIteration-scenario.png`,
  //     src: `${assetPath}beanstalk-previousIteration-scenario-thumb.png`,
  //   },{
  //     alt: 'Beanstalk ML UI',
  //     modalSrc: `${assetPath}beanstalk-previousIteration-scenario2.png`,
  //     src: `${assetPath}beanstalk-previousIteration-scenario2-thumb.png`,
  //   },
  //   {
  //     alt: 'Beanstalk ML UI',
  //     modalSrc: `${assetPath}beanstalk-previousIteration-scenario-scrolled.png`,
  //     src: `${assetPath}beanstalk-previousIteration-scenario-scrolled-thumb.png`,
  //   },
  //   {
  //     alt: 'Beanstalk ML UI',
  //     modalSrc: `${assetPath}beanstalk-previousIteration-scenario-tyoh.png`,
  //     src: `${assetPath}beanstalk-previousIteration-scenario-tyoh-thumb.png`,
  //   },
  // ],
  // createScenario: [
  //   {
  //     alt: 'Very early exploration of ad-libs scenario creation',
  //     modalSrc: `${assetPath}beanstalk-createScenario-adlibs.png`,
  //     src: `${assetPath}beanstalk-createScenario-adlibs-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-1.png`,
  //     src: `${assetPath}beanstalk-createScenario-1-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-2.png`,
  //     src: `${assetPath}beanstalk-createScenario-2-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-3.png`,
  //     src: `${assetPath}beanstalk-createScenario-3-thumb.png`,
  //   },{
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-4.png`,
  //     src: `${assetPath}beanstalk-createScenario-4-thumb.png`,
  //   },{
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-5.png`,
  //     src: `${assetPath}beanstalk-createScenario-5-thumb.png`,
  //   },{
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-6.png`,
  //     src: `${assetPath}beanstalk-createScenario-6-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-7.png`,
  //     src: `${assetPath}beanstalk-createScenario-7-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-8.png`,
  //     src: `${assetPath}beanstalk-createScenario-8-thumb.png`,
  //   },
  //   {
  //     alt: 'Early mockup of creating a new scenario in Beanstalk.',
  //     modalSrc: `${assetPath}beanstalk-createScenario-9.png`,
  //     src: `${assetPath}beanstalk-createScenario-9-thumb.png`,
  //   },
  // ],
};

export const ProjectGallery = ({ spotifyInternalRoutes }) => (
  <Box bg="card.bg.base" color="card.text" id={spotifyInternalRoutes.gallery}>
    <PaletteBar />
    <SectionDivider />
    <SectionHeader color="white" title="Gallery" />

    <Box paddingX={theme.space.viewportPaddingX}>
      <SectionDivider height="half" />
      <GallerySection
        gallery={gallery.deliverables}
        title="Deliverables"
      />
      <GalleryDivider />
      <GallerySection
        gallery={gallery.artifacts}
        title="Artifacts"
      />
    </Box>
    <SectionDivider height="half" />
  </Box>
);

ProjectGallery.propTypes = {
  grid: PropTypes.object,
  spotifyInternalRoutes: PropTypes.object.isRequired,
  subtitle: PropTypes.object,
};
