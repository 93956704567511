import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Icon } from '@chakra-ui/core';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import {
  Box,
  Flex
} from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import { PaletteBar } from 'components/PaletteBar';
import { ResponsiveHide } from 'components/ResponsiveHide';
import { ScrollProgressBar } from 'components/ScrollProgressBar';


const NavSeparator = () => (
  <Icon
    color="app.text"
    marginX={{
      base: '2px',
      [LAYOUT_SWITCH]: 2,
    }}
    name="arrow-forward"
    size="0.75rem"
  />
);

const LinkToTop = ({ inView, spotifyInternalRoutes }) => (
  <Flex
    alignItems="center"
    display={{
      base: 'none',
      [LAYOUT_SWITCH]: 'flex',
    }}
    gridColumn={{
      base: 'span 1',
      xl: 'a0b0 / a1',
    }}
    justifyContent="flex-start"
    opacity={ !! inView ? 0 : 1}
  >
    <NavItem
      _active={{
        bg: 'scheme.primary.lighter',
        borderColor: 'transparent',
        color: 'scheme.primary.darker',
      }}
      _hover={{
        bg: 'white',
        borderColor: 'currentColor',
        color: 'scheme.primary.dark',
      }}
      bg="scheme.primary.base"
      color="white"
      icon="arrow-up"
      label="Top"
      section={spotifyInternalRoutes.top}
    />
  </Flex>
);

LinkToTop.propTypes = {
  inView: PropTypes.bool,
  spotifyInternalRoutes: PropTypes.object.isRequired,
};

const NavItem = ({ icon, label, labelSmall, section, ...props }) => (
  <LinkInternal
    _active={{
      bg: 'scheme.secondary.lighter',
      borderColor: 'transparent',
      color: 'scheme.secondary.darker',
    }}
    _hover={{
      bg: 'white',
      borderColor: 'currentColor',
      color: 'scheme.secondary.dark',
    }}
    bg={{
      base: 'transparent',
      [LAYOUT_SWITCH]: 'whiteAlpha.300'
    }}
    color="scheme.secondary.dark"
    fontSize={{
      base: 'xs',
      [LAYOUT_SWITCH]: 'sm'
    }}
    href={`#${section}`}
    paddingX={2}
    paddingY={2}
    {...props}
  >
    {!! icon &&
      <Icon fontSize="0.75em" marginRight={1} name={icon} />
    }
    { !! labelSmall && <span>
      <ResponsiveHide base="inline" md="none">{labelSmall}</ResponsiveHide>
      <ResponsiveHide base="none" md="inline">{label}</ResponsiveHide>
    </span>
    }
    {! labelSmall && label}
  </LinkInternal>
);

NavItem.propTypes ={
  icon: PropTypes.string,
  label: PropTypes.string,
  labelSmall: PropTypes.string,
  section: PropTypes.string.isRequired,
};


export const SpotifyNav = ({ scollProgressElement, spotifyInternalRoutes }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  return (<>
    <Box
      ref={intersectionObserverRef}
      style={{ pointerEvents: 'none' }}
      transform="translate3d(0, -15vh, 0)"
    />
    <Gridset
      alignItems="stretch"
      backgroundImage={`
        linear-gradient(to bottom, rgba(255,255,255, 0.5), rgba(255,255,255, 0.001)),
        linear-gradient(to right, rgba(255,255,255, 0.001), rgba(255,255,255, 0.7), rgba(255,255,255, 0.001))
      `}
      bg="whiteAlpha.800"
      maxWidth="100vw"
      overflowX="auto"
      paddingX={theme.space.viewportPaddingX}
      paddingY={2}
      position={{
        base: 'fixed',
        [LAYOUT_SWITCH]: 'sticky'
      }}
      style={{
        WebkitBackdropFilter: 'blur(10px)',
        backdropFilter: 'blur(10px)',
      }}
      top={{
        base: 'calc(2.75rem - 1px)',
        [LAYOUT_SWITCH]: '-1px', // `0` can cause gaps from rounding errors. Weird.
      }}
      width="100vw"
      zIndex="sticky"
    >
      <LinkToTop inView={inView} spotifyInternalRoutes={spotifyInternalRoutes} />
      <Flex
        alignItems="center"
        gridColumn={{
          base: 'span 12',
          [LAYOUT_SWITCH]: 'a05 / a55',
          xl: 'a1 / a5',
        }}
        justifyContent={{
          base: 'flex-start',
          [LAYOUT_SWITCH]: 'center'
        }}
      >
        <NavItem label="Mission" section={spotifyInternalRoutes.mission} />
        {/*
        <NavSeparator />
        <NavItem label="Team" section={spotifyInternalRoutes.team} />
        */}
        <NavSeparator />
        <NavItem label="The Work" section={spotifyInternalRoutes.project} />
        <NavSeparator />
        <NavItem label="Gallery" section={spotifyInternalRoutes.gallery} />
      </Flex>
      <ScrollProgressBar
        bottom={0}
        element={scollProgressElement}
        height={{
          base: '4px',
          [LAYOUT_SWITCH]: '5px',
          lg: '6px',
        }}
        left={0}
        pointerEvents="none"
        position="absolute"
        right={0}
      />
    </Gridset>
    <PaletteBar height="0.25rem" />
  </>);
};

SpotifyNav.propTypes = {
  scollProgressElement: PropTypes.object.isRequired,
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
