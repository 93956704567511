import netlifyIdentity from 'netlify-identity-widget';


export function getUser() {
  if (!! netlifyIdentity) { return netlifyIdentity.currentUser(); }
  return null;
};

export function handleLogIn() { netlifyIdentity.open(); };

export function handleLogOut() { netlifyIdentity.logout(); };

export const isLoggedIn = (user = getUser()) => !! user;
