import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { OverviewItem } from 'components/OverviewItem';
import { SectionHeader } from 'components/SectionHeader';
import {
  BodyAlt,
  Nbsp,
} from 'components/Typography';


export const Mission = () => (<>
  <SectionHeader color="white" title="Mission" />
  <Gridset
    alignItems="stretch"
    color="white"
    paddingX={theme.space.viewportPaddingX}
    paddingY={{
      base: 4,
      [LAYOUT_SWITCH]: 0,
    }}
  >
    <OverviewItem
      gridColumn={{
        base: 'span 12',
        [LAYOUT_SWITCH]: 'a05 / a3b2',
      }}
      header="Mission"
      title={<span>Increase frequency and utility of behavioral tests.</span>}
      zIndex={0}
    >
      <BodyAlt as="p">Enable product teams with new tools and repeatable processes to discover and influence the complex user behaviors behind growth<Nbsp/>metrics to answer the fundamental questions:</BodyAlt>
      <BodyAlt as="p" color="dangerPalette.flax.base" marginTop={2}><strong>When people do <em>x</em>, they convert. What leads more people to do <em>x</em>, and will it still cause conversion?</strong></BodyAlt>
      
    </OverviewItem>
    <OverviewItem
      gridColumn={{
        base: 'span 12',
        [LAYOUT_SWITCH]: 'a3b2 / a55',
      }}
      header="Product"
      title={ <span>Hypothesis<Nbsp/>generator.</span> }
      zIndex={2}
    >
      <BodyAlt as="p">
        Web app for discovering user behaviors that cause engagement, conversion, and retention outcomes.
      </BodyAlt>
      <BodyAlt as="p" color="dangerPalette.flax.base" marginTop={2}><strong>User Segment</strong> → <strong>Scenario</strong> → <strong>Outcome</strong></BodyAlt>
    </OverviewItem>
  </Gridset>
</>);
