import PropTypes from 'prop-types';
import React from 'react';
import { Spring, Trail } from 'react-spring/renderprops';
import { Icon } from '@chakra-ui/core';
import { getKeyByValue } from 'lib/helpers';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { jobsWithPage, routes } from 'lib/router/routes';
import { Box, Flex } from 'components/Layout';
import { AppNavLink } from './AppNavLink';


export const AppNavPortfolio = ({ atHomePage, loggedIn, navStyles }) => {
  if (! loggedIn) return false;
  return (
    <Flex
      alignItems="center"
      flexGrow={1}
      gridColumn={{
        [LAYOUT_SWITCH]: 'a1 / a6b4'
      }}
      gridRow={1}
      overflowX="auto"
      paddingX={{
        base: 2,
        [LAYOUT_SWITCH]: 0
      }}
    >
      { ! atHomePage && <>
        <Trail
          from={{ opacity: 0.25 }}
          items={jobsWithPage}
          keys={jobsWithPage.map((_, i) => i)}
          to={{ opacity: 1 }}
        >
          {job => trailStyle => (
            <AppNavLink
              navStyles={navStyles}
              to={job.portfolioRoute}
              trailStyle={trailStyle}
            >
              { getKeyByValue(routes, job.portfolioRoute) }
            </AppNavLink>
          )}
        </Trail>
        <Spring
          delay={ 200 }
          from={{ opacity: 0.25 }}
          to={{ opacity: 1 }}
          
        >
          {springStyle => (
            <>
              <AppNavLink
                href="https://trips.harlanlewis.com"
                navStyles={navStyles}
                style={springStyle}
                target="_trips"
              >
                Trips Planner
                <Icon marginLeft={1} name="external-link" />
              </AppNavLink>
              <AppNavLink
                href="https://gpt.harlanlewis.com"
                navStyles={navStyles}
                style={springStyle}
                target="_gpt"
              >
                LLM Chat
                <Icon marginLeft={1} name="external-link" />
              </AppNavLink>
            </>
          )}
        </Spring>
      </>}
      {!! atHomePage &&
        <Box
          display={{
            base: 'block',
            md: 'none'
          }}
        >
          <AppNavLink
            _active={{
              bg: 'scheme.primary.base',
              color: 'white',
              transform: 'scale(0.98)',
            }}
            _hover={{
              bg: 'scheme.primary.dark',
              color: 'white',
              transform: 'scale(1.01)',
            }}
            bg="scheme.primary.base"
            color="whiteAlpha.900"
            navStyles={navStyles}
            to={routes.StrongDM}
          >
            View Work
          </AppNavLink>
        </Box>
      }
      <Box flexGrow={0} flexShrink={0} height="100%" width={2} />
    </Flex>
  );
};

AppNavPortfolio.propTypes = {
  atHomePage: PropTypes.bool,
  loggedIn: PropTypes.bool.isRequired,
  navStyles: PropTypes.object.isRequired,
};
