import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { routes } from 'lib/router/routes';


export const PrivateRoute = ({ children, loggedIn, ...props }) => (
  <Route
    { ...props}
    render={
      () => loggedIn ? children : <Redirect to={ routes.Home } />
    }
  />
);

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};
