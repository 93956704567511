import PropTypes from 'prop-types';
import React from 'react';
import { Gridset } from 'components/Gridset';
import { LinkInternal } from 'components/LinkInternal';
import { spotifyInternalRoutes } from 'pages/Portfolio/Spotify/spotifyInternalRoutes';

import { ArrowRow } from './Arrows';
import { LayerCard } from './LayerCard';

const content = {
  'goal': [
    'Improve Beanstalk’s usability and interpretability.',
  ],
  'data': [
    'Product Makers do not understand or know how to use Beanstalk metrics.',
    'Beanstalk users need behaviors put in context to understand how to act on them.',
    'Beanstalk users want more guidance on which insights they should take action on.',
  ],
  'hypotheses': [
    'Visual indicators of how a behavior fits into the Spotify feature set will help users understand the behavior’s context and how to target it.',
  ],
  'solutions': [
    'Generate screenshots of where the event is triggered in the Spotify app.',
    <span>Create and assign <strong>Behavioral Tags</strong> to categorize behaviors based on what the user is doing (playlist curation, social sharing, podcast discovery, etc).</span>,
    'Data visualization displaying when and where the event occurs in a user path.',
  ],
  'evaluations': [
    <span>Feauture-specific usage and task-completion metrics, and gather <LinkInternal href={`#${spotifyInternalRoutes.exploration}`}>feedback</LinkInternal> from users matching our <LinkInternal href={`#${spotifyInternalRoutes.audience}`}>personas</LinkInternal> focused on the impact of <strong>Behavioral Tagging</strong> on interpretability.</span>
  ],
};

export const LayerContent = ({ level }) => {
  const cardCount = content[level].length;
  return (
    <Gridset
      alignItems="center"
      alignSelf="stretch"
      gridColumn={{ base: 'b1 / a6b4' }}
      gridRow={1}
      justifyContent="center"
      position="relative"
    >
      { level !== 'goal' && cardCount > 0 && <ArrowRow count={cardCount} /> }
      { content[level].map((content, i) => (
        <LayerCard
          cardCount={cardCount}
          children={content}
          indexPlusOne={(i + 1)}
          key={i}
        />
      )) }
    </Gridset>
  );
};

LayerContent.propTypes = {
  level: PropTypes.string.isRequired,
};
