import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Spring } from 'react-spring/renderprops';
import {
  Icon,
  Image,
  useDisclosure,
} from '@chakra-ui/core';
import {
  Box,
  Grid,
  PseudoBox,
} from 'components/Layout';
import { ImageModal } from 'components/ImageModal';


function springTo(inView, isAnimated) {
  if (!! isAnimated) {
    return {
      opacity: !! inView ? 1 : 0.1,
      transform: !! inView ? 'scale(1)' : 'scale(0.9)',
    };
  }
  return {
    // empty object or null throws an error, so just do something safe
    opacity: 1,
  };
};

const internalProps = {
  as: 'figure',
  bg: 'black',
  borderRadius: 'lg',
  boxShadow: 1,
  display: 'inline-block',
  overflow: 'hidden',
  position: 'relative',
};

export const ArticleImage = ({ alt, isAnimated = true, modalSrc, src, triggerOnce, ...props }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (! modalSrc) {
    return (
      <Box {...internalProps} {...props} ref={intersectionObserverRef}>
        <Spring to={springTo(inView, isAnimated)}>
          { springStyle =>
            (<Image
              alt={alt}
              src={src}
              style={springStyle}
              transformOrigin="bottom center"
            />)
          }
        </Spring>
      </Box>
    );
  }

  const handleClick = (e) => {
    e.preventDefault();
    onOpen();
  };

  return (
    <Box ref={intersectionObserverRef}>
      <Spring to={springTo(inView, isAnimated)}>
        {springStyle => (
          <Box
            {...internalProps}
            {...props}
            style={springStyle}
            transformOrigin="bottom center"
          >
            <PseudoBox
              _hover={{
                bg: 'modal.imageHover',
                color: 'white',
              }}
              as="a"
              bg="transparent"
              color="transparent"
              cursor="zoom-in"
              height="100%"
              href={modalSrc}
              left={0}
              onClick={handleClick}
              position="absolute"
              top={0}
              width="100%"
              zIndex="float"
            >
              <Grid alignItems="center" fontSize="2rem" height="100%" justifyItems="center" width="100%">
                <Icon name="search" />
              </Grid>
            </PseudoBox>
            <Image alt={alt} src={src} />
            <ImageModal alt={alt} isOpen={isOpen} onClose={onClose} src={modalSrc} />
          </Box>
        )}
      </Spring>
    </Box>
  );
};

ArticleImage.propTypes = {
  alt: PropTypes.string.isRequired,
  isAnimated: PropTypes.bool,
  modalSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  triggerOnce: PropTypes.bool,
};
