import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { SectionDivider } from 'components/Divider';
import {
  GalleryDivider,
  GallerySection,
} from 'components/GallerySection';
import { Box } from 'components/Layout';
import { PaletteBar } from 'components/PaletteBar';
import { SectionHeader } from 'components/SectionHeader';


const assetPath = {
  inStore: '/assets/portfolio/rafter/inStore/',
  pointOfSale: '/assets/portfolio/rafter/inStore/pointOfSale/',
};

const gallery = {
  pointOfSale: [
    {
      alt: 'Point of Sale - select store location',
      modalSrc: `${assetPath.pointOfSale}1-select-store-location.png`,
      src: `${assetPath.pointOfSale}1-select-store-location.png`,
    },
    {
      alt: 'Point of Sale - scan first book',
      modalSrc: `${assetPath.pointOfSale}2-point-of-sale-landing.png`,
      src: `${assetPath.pointOfSale}2-point-of-sale-landing.png`,
    },
    {
      alt: 'Point of Sale - select book condition',
      modalSrc: `${assetPath.pointOfSale}3-book-scanned-condition.png`,
      src: `${assetPath.pointOfSale}3-book-scanned-condition.png`,
    },
    {
      alt: 'Point of Sale - view cart',
      modalSrc: `${assetPath.pointOfSale}4-order-summary.png`,
      src: `${assetPath.pointOfSale}4-order-summary.png`,
    },
    {
      alt: 'Point of Sale - customer email address',
      modalSrc: `${assetPath.pointOfSale}5-customer-account.png`,
      src: `${assetPath.pointOfSale}5-customer-account.png`,
    },
    {
      alt: 'Point of Sale - verify user ID',
      modalSrc: `${assetPath.pointOfSale}6-verify-account.png`,
      src: `${assetPath.pointOfSale}6-verify-account.png`,
    },
    {
      alt: 'Point of Sale - create an account',
      modalSrc: `${assetPath.pointOfSale}7-create-account.png`,
      src: `${assetPath.pointOfSale}7-create-account.png`,
    },
    {
      alt: 'Point of Sale - payment',
      modalSrc: `${assetPath.pointOfSale}8-payment.png`,
      src: `${assetPath.pointOfSale}8-payment.png`,
    },
    {
      alt: 'Point of Sale - order complete',
      modalSrc: `${assetPath.pointOfSale}9-order-complete.png`,
      src: `${assetPath.pointOfSale}9-order-complete.png`,
    },
    {
      alt: 'Point of Sale - whiteboarding',
      modalSrc: `${assetPath.inStore}pos-whiteboard.jpg`,
      src: `${assetPath.inStore}pos-whiteboard.jpg`,
    },
    {
      alt: 'Point of Sale - wireframe',
      modalSrc: `${assetPath.inStore}pos-wireframe-balsamiq.png`,
      src: `${assetPath.inStore}pos-wireframe-balsamiq.png`,
    },
    {
      alt: 'Point of Sale - early UI',
      modalSrc: `${assetPath.inStore}pos-earlyUI.png`,
      src: `${assetPath.inStore}pos-earlyUI.png`,
    },
  ],
  pointOfSaleIterations: [
    {
      alt: 'Point of Sale - book not available',
      modalSrc: `${assetPath.pointOfSale}alt-scan-not-available.png`,
      src: `${assetPath.pointOfSale}alt-scan-not-available.png`,
    },
    {
      alt: 'Point of Sale - mixed online and in-store',
      modalSrc: `${assetPath.pointOfSale}alt-book-list-with-online.png`,
      src: `${assetPath.pointOfSale}alt-book-list-with-online.png`,
    },
    {
      alt: 'Point of Sale - verify account',
      modalSrc: `${assetPath.pointOfSale}alt-verify-account.png`,
      src: `${assetPath.pointOfSale}alt-verify-account.png`,
    },
    {
      alt: 'Point of Sale - ship to customer',
      modalSrc: `${assetPath.pointOfSale}alt-ship-to-customer.png`,
      src: `${assetPath.pointOfSale}alt-ship-to-customer.png`,
    },
  ],
  inventory: [
    {
      alt: 'In-store receiving of shipments.',
      modalSrc: `${assetPath.inStore}rapidReceive.png`,
      src: `${assetPath.inStore}rapidReceive.png`,
    },
    {
      alt: 'Early exploration of in-store pickup of online orders',
      modalSrc: `${assetPath.inStore}RapidPickup_balsamiq.png`,
      src: `${assetPath.inStore}RapidPickup_balsamiq.png`,
    },
    {
      alt: 'Workflow for in-store point of sale.',
      modalSrc: `${assetPath.inStore}pos-workflow.png`,
      src: `${assetPath.inStore}pos-workflow.png`,
    },
  ],
};

export const Gallery = ({ rafterInternalRoutes }) => (
  <Box bg="card.bg.base" color="card.text" id={rafterInternalRoutes.gallery}>
    <PaletteBar />
    <SectionDivider />
    <SectionHeader color="white" title="Gallery" />

    <Box paddingX={theme.space.viewportPaddingX}>
      <SectionDivider height="half" />
      <GallerySection
        gallery={gallery.pointOfSale}
        title="In-Store Point of Sale"
      />
      <GalleryDivider />
      <GallerySection
        gallery={gallery.pointOfSaleIterations}
        title="Iterations on Point of Sale"
      />
      <GalleryDivider />
      <GallerySection
        gallery={gallery.inventory}
        title="Inventory Management"
      />
    </Box>
    <SectionDivider height="half" />
  </Box>
);

Gallery.propTypes = {
  grid: PropTypes.object,
  rafterInternalRoutes: PropTypes.object.isRequired,
  subtitle: PropTypes.object,
};
