import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'lib/mediaQuery';
import { GRIDSET_OVERLAY, theme } from 'lib/theme';
import { Box } from 'components/Layout';


export const Aside = ({ children, fontSize, fontStyle, fontWeight, isOutset, isPadded, placement, typographyEnabled, ...props }) => {
  const currentBreakpoint = useMediaQuery();

  const narrow = placement === 'narrow';
  const wide = placement === 'wide';
  const left = placement === 'left';
  const right = placement === 'right';

  if (!! isOutset && (!! narrow || !! wide)) isOutset = false; // collision handling: `wide` and `narrow` take precedence over `isOutset`

  const adjustForColumns = {
    // adjust percentage widths by the number of columns they're placed in
    md: 12 / 10,
    lg: 12 / 8,
  };

  return (
    <Box
      as={(!! narrow || !! wide) ? 'section' : 'aside'}
      bg={ !! GRIDSET_OVERLAY && {
        base: 'scheme.primary.600',
        sm: 'scheme.primary.200',
        md: 'scheme.secondary.200',
        lg: 'scheme.tertiary.200',
        xl: 'scheme.accent.200',
      }}
      className={ !! typographyEnabled ? 'typographyEnabled' : null /* `null` must be set to avoid rendering `class=false` */}
      float={{
        base: 'none',
        md: (!! left || !! right) ? placement : 'none',
      }}
      fontSize={ fontSize || (! typographyEnabled && ! wide && 'xs') }
      fontStyle={ fontStyle || (! typographyEnabled && 'normal') }
      fontWeight={ fontWeight || (! typographyEnabled && 'normal') }
      marginLeft={
        !! isOutset ? {
          base: (!! left || !! right) && 0.5 * theme.sizes.gridset.col6 + '%',
          md: !! left ? -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%' : 0,
          lg: !! left && -1 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%',
        } : {
          base: (!! left || !! right) ? 0.5 * theme.sizes.gridset.col6 + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col6 + '%'
              : !! wide && 0,
          md: !! left ? -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
              : !! wide ? -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
                : 0,
          lg: !! left ? -1 * theme.sizes.gridset.col4 + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col4 + '%'
              : !! wide ? -0.5 * theme.sizes.gridset.col4 + '%'
                : 0,
          xl: !! left ? -0.5 * theme.sizes.gridset.col4 + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col4 + '%'
              : !! wide ? -0.5 * theme.sizes.gridset.col4 + '%'
                : 0,
        }
      }
      marginRight={
        !! isOutset ? {
          md: !! right && -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%',
          lg: !! right && -1 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%',
        } : {
          md: !! right ? -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
              : !! wide && -0.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%',
          lg: !! right ? -1 * theme.sizes.gridset.col4 + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col4 + '%'
              : !! wide && -0.5 * theme.sizes.gridset.col4 + '%',
          xl: !! right ? -0.5 * theme.sizes.gridset.col4 + '%'
            : !! narrow ? 0.5 * theme.sizes.gridset.col4 + '%'
              : !! wide && -0.5 * theme.sizes.gridset.col4 + '%',
        }
      }
      paddingBottom={{ base: !! isPadded ? 8 : 0, md: !! isPadded ? 6 : 0 }}
      paddingLeft={{
        md: !! isPadded && !! right ? 8 : 0
      }}
      paddingRight={{
        md: !! isPadded && !! left ? 8 : 0
      }}
      paddingTop={
        !! isPadded && (!! wide || !! narrow) ? { base: !! isPadded ? 8 : 0, md: !! isPadded ? 6 : 0 }
          : !! isPadded ? 2 : 0 }
      style={{
        clear: currentBreakpoint !== 'lg' && currentBreakpoint !== 'xl' && 'both',
      }}
      textAlign= {{
        base: 'center',
        md: (!! narrow || !! wide) ? 'center' : !! left ? 'right' : 'left',
      }}
      width={
        !! isOutset ? {
          base: (!! left || !! right) && 5 * theme.sizes.gridset.col6 + '%',
          md: 1.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%',
          lg: theme.sizes.gridset.col6 * adjustForColumns.lg + '%',
        } : {
          base: (!! left || !! right) ? 5 * theme.sizes.gridset.col6 + '%'
            : !! narrow && 5 * theme.sizes.gridset.col6 + '%',
          md: (!! left || !! right) ? 2.5 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
            : !! narrow ? 4 * theme.sizes.gridset.col6 * adjustForColumns.md + '%'
              : !! wide && 6 * theme.sizes.gridset.col6 * adjustForColumns.md + '%',
          lg: (!! left || !! right) ? 2 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%'
            : !! narrow ? 3 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%'
              : !! wide && 5 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%',
          xl: (!! left || !! right) ? 1.5 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%'
            : !! narrow ? 3 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%'
              : !! wide && 5 * theme.sizes.gridset.col6 * adjustForColumns.lg + '%',
        }
      }
      {...props}
    >
      {children}
    </Box>
  );
};

Aside.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  fontStyle: PropTypes.string,
  fontWeight: PropTypes.string,
  isOutset: PropTypes.bool,
  isPadded: PropTypes.bool,
  placement: PropTypes.oneOf(['narrow', 'wide', 'left', 'right']).isRequired,
  typographyEnabled: PropTypes.bool,
};
