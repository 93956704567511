import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { routes } from 'lib/router/routes';
import { theme } from 'lib/theme';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import { SectionHeader } from 'components/SectionHeader';
import {
  HeaderDecorated,
} from 'components/Typography';


export const ProjectBackground = ({ spotifyInternalRoutes }) => (<>
  <SectionDivider height="half" id={spotifyInternalRoutes.background} />
  <SectionDivider display={{ base: 'none', lg: 'block' }} height="half" />
  <Gridset
    alignItems="stretch"
    paddingX={theme.space.viewportPaddingX}
  >
    <Box
      gridColumn={{
        base: 'span 12',
        [LAYOUT_SWITCH]: 'a05 / a35',
        lg: 'a1 / a35',
      }}
      paddingRight={{
        base: 4,
        [LAYOUT_SWITCH]: 8,
      }}
    >
      <SectionHeader alignItems="flex-start" colorDecoration="scheme.secondary.dark" title="Project Background" />
      <Box className="typographyEnabled">
        <p>After Spotify acquired <LinkInternal to={routes.Preact}>Preact</LinkInternal>, we stripped the Ptero user insights product to a base set of functionality and design so that we could purposefully redesign it for Spotify’s internal use cases and design language.</p>

        <p>I worked with our PM to get feedback and validate the usefulness of this early version by partnering with various growth and retention teams. One of our partners increased velocity from running 2 behavioral experiments per quarter to over 40 in a month.</p>

        <p>In this project, I'll go over the design effort I led to make our system’s findings easier to interpret and apply by more teams.</p>
      </Box>
    </Box>

    <Box
      className="typographyEnabled"
      gridColumn={{
        base: 'span 12',
        [LAYOUT_SWITCH]: 'a35 / a55',
        lg: 'a35 / a5',
      }}
      paddingLeft={{
        [LAYOUT_SWITCH]: 8,
      }}
      paddingTop={1} // align with section Title
    >
      <HeaderDecorated>
        Timeline
      </HeaderDecorated>
      <Box fontSize="sm" marginBottom={6} marginTop={-2}>
        <p>~1 quarter</p>
      </Box>

      <HeaderDecorated>
        Objective
      </HeaderDecorated>
      <Box fontSize="sm" marginBottom={6} marginTop={-2}>
        <p>Enable rapid experimentation at greater scale by removing user dependence on the <em>Insights & Experiments</em> team to interpret and use behavioral insights.</p>
      </Box>

      <HeaderDecorated>
        Key Result
      </HeaderDecorated>
      <Box fontSize="sm" marginBottom={6} marginTop={-2}>
        <p>Measurably improve data interpretability and system usability by users matching our <LinkInternal href={`#${spotifyInternalRoutes.audience}`}>personas</LinkInternal>, effectively transforming the UI from ML scaffolding to a self-serve tool.</p>
      </Box>

      <HeaderDecorated>
        UX Evaluation Methods
      </HeaderDecorated>
      <Box fontSize="sm" marginBottom={6} marginTop={-2}>
        <ol>
          <li>System Usability Scale</li>
          <li>Scripted User Testing</li>
          <li>Gathered Feedback</li>
        </ol>
      </Box>
    </Box>
  </Gridset>
</>);

ProjectBackground.propTypes = {
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
