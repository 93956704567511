import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'lib/helmet';
import { getJob } from 'lib/helpers';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { routes } from 'lib/router/routes';
import { ArticleHeader } from 'components/ArticleHeader';
import { ArticleImage } from 'components/ArticleImage';
import { ArticleBody } from 'components/ArticleBody';
import { Aside } from 'components/Aside';
import { SectionDivider } from 'components/Divider';
import { Box } from 'components/Layout';
import { LinkExternal } from 'components/LinkExternal';
import { LinkInternal } from 'components/LinkInternal';
import {
  SubtitleDecorated,
  Header,
} from 'components/Typography';
import { Video } from 'components/Video';

import { preactInternalRoutes } from './preactInternalRoutes';
import { Gallery } from './Gallery';
import { Products } from './Products';


const job = getJob('Preact');

export const Preact = () => (<>
  <Helmet>
    <title>{pageTitle(job.org)}</title>
  </Helmet>
  <Box as="article">
    <ArticleHeader
      titlePrimary={job.org}
      titleSecondary={job.title}
      titleTertiary={
        `${(!! job.tenureStart && job.tenureStart !== job.tenureEnd) ? job.tenureStart + ' – ' : ''}${job.tenureEnd}`
      }
    />
    <ArticleBody>
      <p>Preact was acquired by <LinkInternal to={routes.Spotify}>Spotify</LinkInternal> in 2016.</p>
      <p>As Head of Product Design at Preact:</p>
      <ul>
        <li>Owned design process, production, and outcomes for multiple SKUs.</li>
        <li>Created and maintained design systems across all products.</li>
        <li>Established UX standards, enabling and promoting user research throughout the product development lifecycle.</li>
        <li>Designed and developed interactive data visualizations.</li>
      </ul>

      <SectionDivider height="half" />

      <SectionDivider height="half" id={preactInternalRoutes.products} />
      <Products />

      <SectionDivider />
      <Aside
        isPadded
        marginBottom={{ [LAYOUT_SWITCH]: '1rem' }}
        placement="right"
        position="relative"
        transform={{ [LAYOUT_SWITCH]: 'translate3d(0, 1rem, 0)' }}
        zIndex="float"
      >
        <ArticleImage
          alt="Updated design for filter UI."
          modalSrc="/assets/portfolio/preact/preact/createFilter-new.png"
          src="/assets/portfolio/preact/preact/createFilter-new.png"
        />
      </Aside>
      <Aside isPadded placement="right">
        <ArticleImage
          alt="Original design for filter UI."
          modalSrc="/assets/portfolio/preact/preact/createFilter-old.png"
          src="/assets/portfolio/preact/preact/createFilter-old.png"
        />
      </Aside>
      <SubtitleDecorated decorationColor="dangerPalette.turquoise.base">Preact</SubtitleDecorated>
      <p>The UI surface for <strong>Preact</strong>’s flagship product consisted of a web app and wide variety of integrations. When I joined Preact, I led unification and visual cohesion of the design system across these touchpoints, and extended the system to new product offerings.</p>
      <Header>Intelligent Messaging</Header>
      <p>While exploring ways to automate Customer Success Manager tasks that would increase their customer engagement and retention, we developed the concept of Intelligent Messaging. Our mantra was <q>send the right message, to the right user, at the right time</q>.</p>
      <p>I worked closely with product and data science to develop this system from conception.</p>
      <Aside isPadded placement="right">
        <ArticleImage
          alt="Original design for filter UI."
          modalSrc="/assets/portfolio/preact/preact/intelligentMessaging/intelligentMessaging-composer.png"
          src="/assets/portfolio/preact/preact/intelligentMessaging/intelligentMessaging-composer.png"
        />
      </Aside>
      <ol>
        <li>We used machine learning to:
          <ol>
            <li>Identify the specific behaviors that led to success,</li>
            <li>identify the users who had not yet matched those behaviors,</li>
            <li>and define segments of users based on their activity profile likely to be influenced.</li>
          </ol>
        </li>
        <li>Once we had user segments and behavior goals, we defined behavioral triggers to queue messages for users.</li>
        <li>Finally, we sent messages to individual users based on their activity profile (targeting the days and times of their peak activity), subject to volume limits to avoid filling inboxes.</li>
      </ol>
      <p>It was crucial that these messages were designed to be seen as <em>beneficial guidance</em> on how to effectively use the platform or service, rather than re-engagement spam.</p>
      <p>When previewing this concept with existing Preact customers, we were initially disappointed to find a more reactive mindset and methodology that impeded adoption of predictive automated messaging.</p>
      <p>However, we found a very strong signal in other departments, particularly growth marketing.</p>
      <p>This discovery led to the development of a standalone tool, <strong>Ptero</strong>, designed to empower non-technical growth marketers with self-serve behavioral data science.</p>

      <SectionDivider />

      <Aside isPadded placement="wide">
        <Video
          loop
          src="/assets/portfolio/preact/ptero/video-scenarioDescription.mp4"
        />
      </Aside>
      <SectionDivider height="half" />
      <Aside isPadded placement="right">
        <ArticleImage
          alt="Whiteboarding the Ptero UI."
          modalSrc="/assets/portfolio/preact/ptero/early-whiteboardScenario.jpg"
          src="/assets/portfolio/preact/ptero/early-whiteboardScenario.jpg"
        />
      </Aside>
      <SubtitleDecorated decorationColor="dangerPalette.turquoise.base">Ptero</SubtitleDecorated>
      <p>Within a few months of joining Preact, my time was primarily focused on exploring the Ptero greenfield for growth marketers.</p>
      <p>I led design through the full spectrum of concept to component-based UI, was a major contributing developer in Ptero’s <LinkExternal href="https://reactjs.org">React</LinkExternal> and <LinkExternal href="https://sass-lang.com/">Sass</LinkExternal> app, and worked closely with marketing to develop Ptero’s visual brand.</p>
      <p>After Spotify’s acquisition, Ptero’s technology, web app, and use of automated messaging to influence user behavior were foundational for the growth intelligence and rapid experimentation tools we built there. Porting Ptero to the Spotify use case is detailed in the <LinkInternal to={routes.Spotify}>Spotify Case Study</LinkInternal>.</p>
    </ArticleBody>
    <SectionDivider />
    <Gallery preactInternalRoutes={preactInternalRoutes} />
  </Box>
</>);
