import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Flex,
} from 'components/Layout';


export const PaletteBar = ({ flexDirection, height, width, ...props }) => (
  <Flex
    alignItems="stretch"
    flexDirection={ flexDirection || 'row' }
    height={ height || '0.25rem' }
    justifyContent="stretch"
    width={ width || '100%'}
    {...props}
  >
    <Box bg="dangerPalette.melon.base" flexGrow={1} />
    <Box bg="dangerPalette.peach.base" flexGrow={1} />
    <Box bg="dangerPalette.flax.base" flexGrow={1} />
    <Box bg="dangerPalette.turquoise.base" flexGrow={1} />
    <Box bg="dangerPalette.silverLake.base" flexGrow={1} />
    <Box bg="dangerPalette.purple.base" flexGrow={1} />
  </Flex>
);

PaletteBar.propTypes = {
  flexDirection: PropTypes.oneOf(['column', 'row']),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};
