export const spotifyInternalRoutes = {
  audience: 'spotifyAudience',
  background: 'spotifyBackground',
  cadence: 'spotifyCadence',
  discovery: 'spotifyDiscovery',
  exploration: 'spotifyExploration',
  feedback: 'spotifyFeedback',
  gallery: 'spotifyGallery',
  mission: 'spotifyMission',
  outcomes: 'spotifyOutcomes',
  project: 'spotifyProject',
  team: 'spotifyTeam',
  thoughtfulExecution: 'thoughtfulExecution',
  top: 'root',
};
