import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { ArticleImage } from 'components/ArticleImage';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';


export const ImageRow = ({ images, springRevealTriggerOnce }) => {
  const countLimit = 2;
  if (images.length < 1) {
    console.error('ImageRow was not passed any images.');
    return false;
  } else if (images.length > countLimit) {
    console.error(`ImageRow was passed ${images.length}, but only <=${countLimit} are supported.`);
    return false;
  }

  return (
    <Gridset
      paddingX={theme.space.viewportPaddingX}
      paddingY={{
        base: 4,
        lg: 8,
        xl: 16
      }}
    >
      { images.map((image, i) => (
        <Box
          alignSelf="center"
          gridColumn={
            images.length === 1 ? {
              base: 'span 12',
              [LAYOUT_SWITCH]: 'a1 / a5'
            } : images.length > 1 && (
              i === 0 ? {
                base: 'span 12',
                lg: 'a05 / a3b2'
              } : {
                base: 'span 12',
                lg: 'a3b2 / a55'
              })
          }
          gridRow={{
            base: i + 1,
            lg: 1,
          }}
          justifySelf="center"
          key={i}
          marginBottom={{ lg: i > 0 && -6 }}
          marginLeft={{ lg: i > 0 && -2 }}
          marginRight={{ lg: i === 0 && -2 }}
          marginTop={{ lg: i === 0 && -12 }}
          paddingX={{
            base: 4,
            lg: 0
          }}
          paddingY={{
            base: 2,
            lg: 0
          }}
          position="relative"
          zIndex={ i > 0 ? 'float' : 'base'}
        >
          <ArticleImage
            alt={image.alt}
            maxHeight={{
              [LAYOUT_SWITCH]: i > 0 && '65vmin',
              lg: i > 0 && '50vmin'
            }}
            modalSrc={image.modalSrc}
            src={image.src}
            triggerOnce={springRevealTriggerOnce}
          />
        </Box>
      ))}
    </Gridset>
  );
};

ImageRow.propTypes = {
  images: PropTypes.array.isRequired,
  springRevealTriggerOnce: PropTypes.bool,
};
