import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import {
  Code as ChakraCode,
  Text as ChakraText,
} from '@chakra-ui/core';
import { Decoration } from 'components/Decoration';
import { wireframeStyle } from 'lib/theme';


const styles = {
  headline: {
    lineHeight: 'shorter',
  },
  title: {
    lineHeight: 'short',
  },
  body: {
    lineHeight: 'base',
  }
};

export const Code = forwardRef((props, ref) => (
  <ChakraCode
    bg="code.bg"
    color="code.text"
    display="inline"
    fontWeight="medium"
    style={{
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
    }}
    {...props}
    ref={ref}
  />
));

export const Text = forwardRef((props, ref) => (
  <ChakraText as="div" fontFamily="base" {...props} ref={ref} style={{ ...wireframeStyle(props) }} />
));

export const Nbsp = ({ ...props }) => (
  <Text as="span" display="inline" fontFamily="base" lineHeight="1px" maxHeight="1px" {...props}>&nbsp;</Text>
);

export const Headline1 = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="4xl" fontWeight="bold" letterSpacing="tight" {...props} />
);
export const Headline1Alt = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="4xl" fontWeight="normal" letterSpacing="tight" {...props} />
);

export const Headline2 = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="3xl" fontWeight="bold" {...props} />
);
export const Headline2Alt = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="3xl" fontWeight="normal" letterSpacing="tight" {...props} />
);

export const Headline3 = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="2xl" fontWeight="bold" {...props} />
);
export const Headline3Alt = ({ ...props }) => (
  <Text {...styles.headline} fontFamily="header" fontSize="2xl" fontWeight="normal" letterSpacing="tight" {...props} />
);

export const Title = ({ ...props }) => (
  <Text {...styles.title} fontFamily="header" fontSize="xl" fontWeight="bold" {...props} />
);

export const TitleAlt = ({ ...props }) => (
  <Text {...styles.title} fontFamily="header" fontSize="xl" fontWeight="normal" {...props} />
);

export const Subtitle = ({ ...props }) => (
  <Text {...styles.title} fontFamily="header" fontSize="md" fontWeight="bold" {...props} />
);

export const SubtitleDecorated = ({ decorationColor, ...props }) => (
  <>
    <Subtitle {...props} />
    <Decoration color={decorationColor || 'scheme.accent.dark'} placement="content" />
  </>
);
SubtitleDecorated.propTypes = {
  decorationColor: PropTypes.string,
};

export const Header = ({ ...props }) => (
  <Text {...styles.body} fontFamily="header" fontSize="sm" fontWeight="bold" letterSpacing="wide"  {...props} />
);

export const HeaderDecorated = ({ decorationColor, ...props }) => (
  <>
    <Header  {...props} />
    <Decoration color={decorationColor || 'scheme.accent.dark'} placement="content" />
  </>
);
HeaderDecorated.propTypes = {
  decorationColor: PropTypes.string,
};

export const Body = ({ ...props }) => (
  <Text
    {...styles.body}
    fontSize="1em" // uses `em` instead of `rem` so that paragraph font size can be set by parent
    {...props}
  />
);

export const BodyAlt = ({ ...props }) => (
  <Body {...styles.body} fontSize="sm" {...props} />
);

export const Label = ({ ...props }) => (
  <Text {...styles.body} fontFamily="header" fontSize="sm" fontWeight="medium" letterSpacing="tight" {...props} />
);

export const Small = ({ ...props }) => (
  <Text {...styles.body} fontSize="xs" fontWeight="medium" letterSpacing="tight" {...props} />
);
