import React from 'react';
import { GRIDSET_OVERLAY } from 'lib/theme';
import { ArticleBody } from 'components/ArticleBody';
import { ArticleHeader } from 'components/ArticleHeader';
import { Aside } from 'components/Aside';
import { Box } from 'components/Layout';
import { LinkExternal } from 'components/LinkExternal';
import { LinkInternal } from 'components/LinkInternal';


const asideStyles = {
  borderColor: 'scheme.secondary.base',
  borderWidth: !! GRIDSET_OVERLAY && 1,
  borderStyle: 'dashed',
};

export const GridsetLayout = () => (
  <section>
    <ArticleHeader
      pageHeader="Page Header"
      titlePrimary="Primary Title"
      titleSecondary="Secondary Title"
      titleTertiary="Tertiary Title"
    />
    <ArticleBody>
      <p>Sed ut <LinkExternal to="#">External Link</LinkExternal> unde omnis <LinkInternal>Internal Link</LinkInternal> error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
      <Aside {...asideStyles} isOutset placement="right">
        <Box children="Aside.right isOutset" />
      </Aside>
      <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
      <Aside {...asideStyles} isOutset placement="left">
        <Box children="Aside.left isOutset" />
      </Aside>
      <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.</p>
      <Aside {...asideStyles} placement="left">
        <Box children="Aside.left" />
      </Aside>
      <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
      <Aside {...asideStyles} placement="right">
        <Box children="Aside.right" />
      </Aside>
      <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.</p>
      <Aside {...asideStyles} placement="narrow">
        <Box children="Aside.narrow" />
      </Aside>
      <p>Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
      <Aside {...asideStyles} placement="wide">
        <Box children="Aside.wide" />
      </Aside>
      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.</p>
    </ArticleBody>
  </section>
);
