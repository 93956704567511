import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@chakra-ui/core';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { Box, Flex } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import { AppNavContactPopup } from './AppNavContactPopup';


export const AppNavContact = ({ atHomePage, navStyles }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(! show);

  return (
    <Flex
      alignItems="center"
      flexGrow={1}
      flexShrink={0}
      gridColumn={{
        base: 'a3b2 / a6b4',
        [LAYOUT_SWITCH]: 'a5 / a6b4'
      }}
      gridRow={1}
      justifyContent="flex-end"
      justifySelf="end"
      position="relative"
    >
      <Box
        backgroundImage={{
          base: ! atHomePage ? 'linear-gradient(to right, rgba(255,255,255, 0.001), rgba(255,255,255, 1) 90%)' : 'none',
          [LAYOUT_SWITCH]: 'none'
        }}
        height="100%"
        pointerEvents="none"
        position="absolute"
        right="100%"
        top={0}
        width="1rem"
        zIndex={1}
      />
      <LinkInternal
        bg="scheme.secondary.dark"
        color="white"
        flexShrink={0}
        fontSize={navStyles.fontSize}
        onClick={handleToggle}
        padding={2}
        title="Let's Talk"
      >
        <Icon name="email" />
      </LinkInternal>
      <AppNavContactPopup
        handleToggle={handleToggle}
        show={show}
      />
    </Flex>
  );
};

AppNavContact.propTypes = {
  atHomePage: PropTypes.bool,
  navStyles: PropTypes.object.isRequired,
};
