import { jobDescriptions } from 'lib/jobDescriptions';


export function getPortfolioPath(org) {
  return jobDescriptions[jobDescriptions.findIndex(job => job.org === org)].portfolioRoute;
};

export const jobsWithPage = jobDescriptions.filter((job) => !! job.hasPortfolioPage);

export const routes = {
  Home: '/',
  // LawGives: getPortfolioPath('LawGives'),
  // Quiddities: getPortfolioPath('Quiddities'),
  Preact: getPortfolioPath('Preact'),
  Rafter: getPortfolioPath('Rafter'),
  Spotify: getPortfolioPath('Spotify'),
  StrongDM: getPortfolioPath('StrongDM'),
  Styleguide: '/styleguide',
  // Zazengo: getPortfolioPath('Zazengo'),
};
