import { theme as chakraTheme } from '@chakra-ui/core';
import { palette } from 'lib/palette';

export const WIREFRAME_OUTLINE = true;
export const WIREFRAME = false; // DON'T COMMIT `TRUE`
export const GRIDSET_OVERLAY = false; // DON'T COMMIT `TRUE`

export function wireframeStyle(props) {
  if (! WIREFRAME) { return { ...props.style }; }
  return {
    ...props.style,
    background: !! WIREFRAME && 'initial',
    border: !! WIREFRAME && 'initial',
    borderRadius: !! WIREFRAME && 'initial',
    boxShadow: !! WIREFRAME && 'initial',
    color: !! WIREFRAME && 'initial',
    fontFamily: !! WIREFRAME && 'sans-serif',
    outline: !! WIREFRAME && WIREFRAME_OUTLINE && '2px solid rgba(0,0,0, 0.05)',
    textShadow: !! WIREFRAME && 'initial',
  };
};

const scheme = {
  primary: { ...palette.purple },
  secondary: { ...palette.melon },
  tertiary: { ...palette.flax },
  accent: { ...palette.peach },
};

export const theme = {
  ...chakraTheme,
  // breakpoints: {
  //   // DO NOT add breakpoints due to error thrown by styled-system
  //   // ALSO, these may be hardcoded elsewhere (eg useMediaQuery hook)
  //   ...chakraTheme.breakpoints,
  // },
  // borders: { ...chakraTheme.borders, },
  colors: {
    /*
      1. import chakraTheme defaults (so existing components can use them)
      2. overwrite with custom palette as appropriate
    */
    ...chakraTheme.colors,
    blue: { ...palette.silverLake },
    // cyan: {},
    // green: {},
    gray: { ...palette.grayscale },
    orange: { ...palette.peach },
    // pink: {},
    purple: { ...palette.purple },
    red: { ...palette.melon },
    teal: { ...palette.turquoise },
    yellow: { ...palette.flax },

    dangerPalette: {
      /*
        DANGER - refrencing palette directly in components
        makes it more difficult to create consistency
        and manage change over time.
        Use SCHEME or purpose-specific theme values instead!
      */
      ...palette,
    },

    /* Purpose-specific theme values start here. */
    app: {
      bg: { ...palette.eggshell },
      divider: chakraTheme.colors.blackAlpha[200],
      text: palette.jet.dark,
      textHighlight: palette.jet.dark,
    },
    button: {
      bg: palette.white,
      bgActive: scheme.primary.dark,
      bgHover: scheme.primary.base,
      color: scheme.primary.darker,
      colorActive: scheme.secondary.dark,
      colorHover: scheme.secondary.base,
    },
    card: {
      bg: {
        active: palette.jet.darker,
        focusOrHover: palette.jet.darker,
        base: palette.jet.dark,
      },
      text: palette.white,
    },
    code: {
      bg: palette.jet[50],
      text: palette.turquoise.darker,
    },
    linkExternal: {
      color: palette.silverLake.darker,
      colorActive: scheme.secondary.darker,
      colorHover: scheme.secondary.dark,
    },
    linkInternal: {
      bg: chakraTheme.colors.blackAlpha[100],
      bgActive: scheme.secondary.lighter,
      bgHover: chakraTheme.colors.white,
      bgSelected: scheme.primary.base,
      color: palette.jet.base,
      colorActive: scheme.secondary.darker,
      colorHover: scheme.secondary.dark,
      colorSelected: chakraTheme.colors.white,
    },
    modal: {
      imageHover: chakraTheme.colors.blackAlpha[300],
    },
    nav: {
      bg: palette.eggshell[300],
    },
    portfolio: {
      spotify: {
        brand: palette.portfolio.spotify,
        persona: {
          maven: { ...palette.turquoise },
          maker: { ...palette.silverLake },
        },
        base: palette.portfolio.spotify,
        lighter: palette.portfolio.spotify,
        light: palette.portfolio.spotify,
        dark: palette.portfolio.spotify,
        darker: palette.portfolio.spotify,
      },
    },
  },

  fonts: {
    ...chakraTheme.fonts,
    base: 'system-ui, sans-serif',
    header: 'Aller, "Brandon Grotesque"',
    monospace: 'inconsolata, consolas, "Liberation Menlo", menlo, monospace',
    sansSerif: 'sans-serif',
    serif: 'serif',
  },
  fontSizes: {
    ...chakraTheme.fontSizes,
    '2xs': '0.65rem',
  },
  // fontWeights: { ...chakraTheme.fontWeights, },
  // icons: { ...chakraTheme.icons, },
  letterSpacings: {
    ...chakraTheme.letterSpacings,
    loose: '0.0125em',
  },
  // lineHeights: { ...chakraTheme.lineHeights, },
  // opacity: { ...chakraTheme.opacity, },
  radii: {
    ...chakraTheme.radii,
    xl: '1rem',
    '2xl': '1.5rem',
  },
  shadows: {
    ...chakraTheme.shadows,
    0: null,
    1: `
      rgba(0,0,0, 0.05) 0 1px 1px 0,
      rgba(0,0,0, 0.1) 0 1px 3px 0`,
    2: `
       rgba(0,0,0, 0.05) 0 1px 1px 0,
       rgba(0,0,0, 0.1) 0 2px 4px -1px,
       rgba(0,0,0, 0.15) 0 3px 6px -1px`,
    3: `
      rgba(0,0,0, 0.05) 0 1px 1px 0,
      rgba(0,0,0, 0.1) 0 6px 6px -3px,
      rgba(0,0,0, 0.2) 0 0.5rem 1rem -0.1rem`,
    4: `
      rgba(0,0,0, 0.05) 0 1px 2px 0,
      rgba(0,0,0, 0.1) 0 0.5rem 1rem -0.15rem,
      rgba(0,0,0, 0.2) 0 0.8rem 1.6rem -0.2rem`,
    5: `
      rgba(0,0,0, 0.05) 0 1px 2px 0,
      rgba(0,0,0, 0.075) 0 0.75rem 0.6rem -0.1rem,
      rgba(0,0,0, 0.125) 0 1rem 2rem -0.15rem,
      rgba(0,0,0, 0.25) 0 1rem 3rem -0.3rem`,
  },

  sizes: {
    ...chakraTheme.sizes,
    aboveFold: 'calc(100vh - 4rem)', // leave a little space at the bottom, and affords room for AppNav
    article: {
      gridColumns: {
        base: 'span 12',
        md: 'a05 / a55',
        lg: 'a1 / a5',
      },
    },
    gridset: {
      col4: 25,
      col6: 16.6666666666,
      gutter: 0,
    },
    gutterInside: '1.5rem',
    gutterOutside: '3rem',
    heightBelowNav: 'calc(100vh - 6rem)',
  },
  space: {
    ...chakraTheme.space,
    carouselCardPaddingX: '1rem',
    gridset: {
      col4: '25%',
      col6: '16.6666666666%',
      gutter: '0%',
    },
    gutterInside: '1.5rem',
    gutterOutside: '3rem',
    viewportPaddingX: {
      // cover all of the breakpoints in cases where this is used by a map
      base: '1rem',
      sm: '1rem',
      md: '2rem',
      lg: '2rem',
      xl: '2rem',
    },
  },
  zIndices: {
    ...chakraTheme.zIndices,
    float: 2,
    max: 2000,
  },
};

theme.colors = {
  ...theme.colors,
  scheme: { ...scheme },
};

// console.log(theme);
// console.log(theme.breakpoints);
// console.log(theme.borders);
// console.log(theme.colors);
// console.log(theme.fonts);
// console.log(theme.fontSizes);
// console.log(theme.fontWeights);
// console.log(theme.icons);
// console.log(theme.letterSpacings);
// console.log(theme.lineHeights);
// console.log(theme.opacity);
// console.log(theme.radii);
// console.log(theme.shadows);
// console.log(theme.sizes);
// console.log(theme.space);
// console.log(theme.zIndices);
