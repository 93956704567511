import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Icon } from '@chakra-ui/core';
import { pageTitle } from 'lib/helmet';
import { getJob } from 'lib/helpers';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { routes } from 'lib/router/routes';
import { ArticleBody } from 'components/ArticleBody';
import { ArticleHeader } from 'components/ArticleHeader';
import { Aside } from 'components/Aside';
import { Callout } from 'components/Callout';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { LinkInternal } from 'components/LinkInternal';
import { Box } from 'components/Layout';
import { Video } from 'components/Video';
import { HeaderDecorated } from 'components/Typography';


const job = getJob('StrongDM');
const assetPath = '/assets/portfolio/strongdm/';
const columnProps = {
  fontSize: 'sm',
  gridColumn: {
    base: 'a05 / a55',
    md: 'a1 / a5',
  },
  paddingX: {
    lg: 6,
  },
  paddingY: {
    base: 4,
    md: 8,
    lg: 0,
  }
};

export const StrongDM = () => {
  const articleRef = useRef(null);
  return (<>
    <Helmet>
      <title>{pageTitle(job.org)}</title>
    </Helmet>
    <Box
      as="article"
      paddingTop={{
        base: 12, // room for sticky scroll nav in small viewports
        [LAYOUT_SWITCH]: 0,
      }}
      ref={articleRef}
    >
      <ArticleHeader
        titlePrimary={job.org}
        titleSecondary={job.title}
        titleTertiary={
          `${(!! job.tenureStart && job.tenureStart !== job.tenureEnd) ? job.tenureStart + ' – ' : ''}${job.tenureEnd}`
        }
      />
      <SectionDivider height="half" />
      <ArticleBody>

        <Callout>
          <p>I haven't covered StrongDM in my portfolio just yet.</p>
          <p><LinkInternal to={routes.Spotify}>Spotify</LinkInternal>, <LinkInternal to={routes.Preact}>Preact</LinkInternal>, and <LinkInternal to={routes.Rafter}>Rafter</LinkInternal> detail some of on my past work shipping impactful product design, or you can see my LLM-driven tools for <LinkInternal href="https://trips.harlanlewis.com" target="_trips">automated trip planning <Icon marginLeft={1} name="external-link" /></LinkInternal> and a model-agnostic <LinkInternal href="https://gpt.harlanlewis.com" target="_gpt">LLM chat app<Icon marginLeft={1} name="external-link" /></LinkInternal>.</p>
        </Callout>

        <SectionDivider height={4} />
        <HeaderDecorated as="p">Inclusive Leader &amp; People Manager</HeaderDecorated>
        <ul>
          <li>Founded and managed embedded product design team in support of 5 fully remote product/engineering lanes.</li>
          <li>Defined and responsible for all design hiring and onboarding processes, definitions of role responsibilities, and ambitious design quality goals.</li>
          <li>Invested in design team process, skill, knowledge, craft, and impact that centered customer needs.</li>
          <li>Created and socialized product design principles to help multi-disciplinary product decision-makers use consistent methodology.</li>
          <li>Assisted strategic partner collaborations by contextualizing long-term problem/opportunity statements with tangible product and service explorations.</li>
        </ul>
        <SectionDivider height={4} />
        <HeaderDecorated as="p">Complex Systems Design</HeaderDecorated>
        <ul>
          <li>Iteratively redesigned full scope of core product for web, Mac, Windows, and Linux (CLI). The redesign enabled sales to enterprise buyers, deployment in more complex and automated environments, and development of novel features at greater speed.</li>
          <li>Developed continuous research programs and repeatable methods for informing design work with our corpus of observed and stated customer experiences.</li>
          <li>Championed adoption of Figma. Created and maintained design system and interaction design guidelines for product across all platforms.</li>
          <li>Design owner of all new product areas, including:
            <ul>
              <li>RBAC and ABAC access control automation</li>
              <li>identity management with a wide range of SSO/IdP providers</li>
              <li>executive buyer reports of infrastructure access and utilization</li>
              <li>universal secrets vault support</li>
              <li>natural language processing of n-parameter security policies</li>
            </ul>
          </li>
        </ul>

        <SectionDivider height="half" />
        <Aside placement="wide">
          <Gridset className="typographyEnabled" paddingX={theme.space.viewportPaddingX}>
            <Box {...columnProps}>
              <Video src={`${assetPath}video-design-critique.mp4`} />
            </Box>
          </Gridset>
        </Aside>
       

        <SectionDivider />

      </ArticleBody>
      
    </Box>
  </>);
};
