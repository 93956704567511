import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { ArticleBody } from 'components/ArticleBody';
import { ArticleImage } from 'components/ArticleImage';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { SectionHeader } from 'components/SectionHeader';
import {
  SubtitleDecorated,
  Code,
} from 'components/Typography';


const columnProps = {
  fontSize: 'sm',
  gridColumn: {
    base: 'a05 / a55',
    md: 'a1 / a5',
    lg: 'span 4',
  },
  paddingX: {
    lg: 6,
  },
  paddingY: {
    base: 4,
    md: 8,
    lg: 0,
  }
};

export const ProjectDiscovery = ({ spotifyInternalRoutes }) => (<>
  <ArticleBody>
    <SectionDivider id={spotifyInternalRoutes.discovery} />
    <SectionHeader alignItems="flex-start" colorDecoration="scheme.secondary.dark" title="Discovery" />
    <p>Customers interacted with early versions of the app in guided sessions. I worked directly with Product Managers and Data Scientists to understand their user segments, goal conditions, and help interpret results generated from the vast Spotify event space across desktops, tablets, phones, smart speakers, gaming consoles, TVs, and other devices.</p>
    <p>A few samples of issues we set out to address, with early scaffolding UI pictured:</p>
  </ArticleBody>

  <SectionDivider height="half" />

  <Gridset className="typographyEnabled" paddingX={theme.space.viewportPaddingX}>
    <Box {...columnProps}>
      <ArticleImage
        alt="An iteration of performance metrics for a user path."
        marginBottom={8}
        modalSrc="/assets/portfolio/spotify/beanstalk-pathMetrics.png"
        src="/assets/portfolio/spotify/beanstalk-pathMetrics.png"
      />
      <SubtitleDecorated>Insights, Not Information</SubtitleDecorated>
      <p>Product Makers needed insights at a glance and direction on what to do next - not more data that required focus and familiarity to understand. Many asked for a unified index score, although it depended on usage if they wanted a score weighted towards things that happened <em>often</em> (describing how users behaved already), or weighted towards the <em>uncommon</em>, and were potentially higher leverage opportunities.</p>
      <p>Conversely, our Data Mavens were familiar with metrics in their domain and could contextualize at a glance - but they needed more data in order to trust and further investigate our system’s results.</p>
    </Box>

    <Box {...columnProps}>
      <ArticleImage
        alt="A user path across multiple devices and subscription states."
        marginBottom={8}
        modalSrc="/assets/portfolio/spotify/beanstalk-compoundPath.png"
        src="/assets/portfolio/spotify/beanstalk-compoundPath.png"
      />
      <SubtitleDecorated>Comprehensive vs Actionable</SubtitleDecorated>
      <p>Our analysis engines were capable of discovering complex Spotify end user paths that followed users across sessions, devices, and subscription states. While useful for telling stories, they proved difficult for our audience to interpret and confidently act on. User paths touching unrelated facets of the Spotify product were exceedingly hard to design experiments for.</p>
    </Box>
    <Box {...columnProps}>
      <ArticleImage
        alt="A Spotify event given a human readable name."
        marginBottom={8}
        modalSrc="/assets/portfolio/spotify/beanstalk-verifiedEvent.png"
        src="/assets/portfolio/spotify/beanstalk-verifiedEvent.png"
      />
      <SubtitleDecorated>Cryptic Events</SubtitleDecorated>
      <p>Event labels were a universal and time-intensive problem. We lacked a comprehensive or current glossary of the events space, so I designed a labelling system and steps to reproduce for every key user event within the administrator controls.</p>
      <p>In the pictured example, <Code bg="code.bg" color="code.text" fontWeight="medium">ui_interaction_discography_albums_lists_add_7dt_any_false</Code> is made a little more friendly to humans with the label <q>clicked add an album to collection from artist page.</q></p>
    </Box>
  </Gridset>
</>);

ProjectDiscovery.propTypes = {
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
