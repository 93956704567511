import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { SectionDivider } from 'components/Divider';
import {
  GalleryDivider,
  GallerySection,
} from 'components/GallerySection';
import { Box } from 'components/Layout';
import { PaletteBar } from 'components/PaletteBar';
import { SectionHeader } from 'components/SectionHeader';


const assetPath = {
  preact: '/assets/portfolio/preact/preact/',
  ptero:  '/assets/portfolio/preact/ptero/',
};

const gallery = {
  ptero: [
    {
      alt: 'Animation of setting scenario color.',
      modalSrc: `${assetPath.ptero}animation-scenarioColors.gif`,
      src: `${assetPath.ptero}animation-scenarioColors.gif`,
    },
    // {
    //   alt: 'Animation of setting scenario description.',
    //   modalSrc: `${assetPath.ptero}animation-scenarioDescription.gif`,
    //   src: `${assetPath.ptero}animation-scenarioDescription.gif`,
    // },
    {
      alt: 'Ptero scenario summary data.',
      modalSrc: `${assetPath.ptero}late-scenarioHeader.png`,
      src: `${assetPath.ptero}late-scenarioHeader.png`,
    },
    {
      alt: 'Ptero user paths to conversion.',
      modalSrc: `${assetPath.ptero}late-userPaths.png`,
      src: `${assetPath.ptero}late-userPaths.png`,
    },
    // {
    //   alt: 'Ptero behavioral event table, with a row hovered.',
    //   modalSrc: `${assetPath.ptero}late-eventTable-hover.png`,
    //   src: `${assetPath.ptero}late-eventTable-hover.png`,
    // },
    {
      alt: 'Ptero behavioral event table, with a row expanded.',
      modalSrc: `${assetPath.ptero}late-eventTable-expanded.png`,
      src: `${assetPath.ptero}late-eventTable-expanded.png`,
    },
  ],
  pteroBeanstalk: [
    // {
    //   alt: 'Dashboard',
    //   modalSrc: `${assetPath.ptero}/beanstalk/0-dashboard.png`,
    //   src: `${assetPath.ptero}/beanstalk/0-dashboard.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/1-dashboard-hoverScenario.png`,
    //   src: `${assetPath.ptero}/beanstalk/1-dashboard-hoverScenario.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/3-dashboard-scenarioForm.png`,
    //   src: `${assetPath.ptero}/beanstalk/3-dashboard-scenarioForm.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/4-scenarioReport.png`,
    //   src: `${assetPath.ptero}/beanstalk/4-scenarioReport.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/5-scenarioReport-event-Timeline.png`,
    //   src: `${assetPath.ptero}/beanstalk/5-scenarioReport-event-Timeline.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/6-scenarioReport-scenarioForm.png`,
    //   src: `${assetPath.ptero}/beanstalk/6-scenarioReport-scenarioForm.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/7-scenario-singleEvents.png`,
    //   src: `${assetPath.ptero}/beanstalk/7-scenario-singleEvents.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/8-scenarioTraining.png`,
    //   src: `${assetPath.ptero}/beanstalk/8-scenarioTraining.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/21-settings-Profile.png`,
    //   src: `${assetPath.ptero}/beanstalk/21-settings-Profile.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/22-settings-Security.png`,
    //   src: `${assetPath.ptero}/beanstalk/22-settings-Security.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/23-settings-Integrations.png`,
    //   src: `${assetPath.ptero}/beanstalk/23-settings-Integrations.png`,
    // },
    // {
    //   alt: '',
    //   modalSrc: `${assetPath.ptero}/beanstalk/24-settings-featureFlags.png`,
    //   src: `${assetPath.ptero}/beanstalk/24-settings-featureFlags.png`,
    // },
  ],
  // pteroExplorations: [
  //   {
  //     alt: 'Early exploration of scenario data.',
  //     modalSrc: `${assetPath.ptero}early-scenarioHeader.png`,
  //     src: `${assetPath.ptero}early-scenarioHeader.png`,
  //   },
  //   {
  //     alt: 'Exploration of scenario dashboard.',
  //     modalSrc: `${assetPath.ptero}mid-dashboard-data.png`,
  //     src: `${assetPath.ptero}mid-dashboard-data.png`,
  //   },
  //   {
  //     alt: 'Early exploration of scenario dashboard.',
  //     modalSrc: `${assetPath.ptero}early-dashboard-data.png`,
  //     src: `${assetPath.ptero}early-dashboard-data.png`,
  //   },
  //   {
  //     alt: 'Early exploration of behavior analysis metrics.',
  //     modalSrc: `${assetPath.ptero}early-behaviorAnalysis.png`,
  //     src: `${assetPath.ptero}early-behaviorAnalysis.png`,
  //   },
  //   {
  //     alt: 'Early exploration of configuring scenario target and qualifying events.',
  //     modalSrc: `${assetPath.ptero}early-scenarioConfig.png`,
  //     src: `${assetPath.ptero}early-scenarioConfig.png`,
  //   },
  //   {
  //     alt: 'Early whiteboard exploring UI for cohort analysis.',
  //     modalSrc: `${assetPath.ptero}early-whiteboardScenario.jpg`,
  //     src: `${assetPath.ptero}early-whiteboardScenario.jpg`,
  //   },
  //   {
  //     alt: 'Early whiteboard exploring split value analysis.',
  //     modalSrc: `${assetPath.ptero}early-whiteboardScenario2.jpg`,
  //     src: `${assetPath.ptero}early-whiteboardScenario2.jpg`,
  //   },
  //   {
  //     alt: 'Early whiteboard exploring scenario flow.',
  //     modalSrc: `${assetPath.ptero}early-whiteboard-scenarioFlow.jpg`,
  //     src: `${assetPath.ptero}early-whiteboard-scenarioFlow.jpg`,
  //   },
  // ],
  preact: [
    {
      alt: 'Preact dashboard with configurable table columns',
      modalSrc: `${assetPath.preact}dashboardDisplayColumns.png`,
      src: `${assetPath.preact}dashboardDisplayColumns.png`,
    },
    {
      alt: 'Preact account details',
      modalSrc: `${assetPath.preact}accountDetails.png`,
      src: `${assetPath.preact}accountDetails.png`,
    },
    // {
    //   alt: 'Preact filter creation UI',
    //   modalSrc: `${assetPath.preact}createFilter-new.png`,
    //   src: `${assetPath.preact}createFilter-new.png`,
    // },
    // {
    //   alt: 'Preact tasks UI',
    //   modalSrc: `${assetPath.preact}tasks.png`,
    //   src: `${assetPath.preact}tasks-thumb.png`,
    // },
    // {
    //   alt: 'Weekly business review email for Customer Success reps',
    //   modalSrc: `${assetPath.preact}weeklyBusinessReview-CSrep.png`,
    //   src: `${assetPath.preact}weeklyBusinessReview-CSrep.png`,
    // },
    {
      alt: 'Weekly business review email for Customer Success management',
      modalSrc: `${assetPath.preact}weeklyBusinessReview-manager.png`,
      src: `${assetPath.preact}weeklyBusinessReview-manager.png`,
    },
  ],
  intelligentMessaging: [
    {
      alt: '',
      modalSrc: `${assetPath.preact}intelligentMessaging/intelligentMessaging-composer.png`,
      src: `${assetPath.preact}intelligentMessaging/intelligentMessaging-composer.png`,
    },
    {
      alt: '',
      modalSrc: `${assetPath.preact}intelligentMessaging/intelligentMessaging-workflow2.png`,
      src: `${assetPath.preact}intelligentMessaging/intelligentMessaging-workflow2.png`,
    },
  ],
};

export const Gallery = ({ preactInternalRoutes }) => (
  <Box bg="card.bg.base" color="card.text" id={preactInternalRoutes.gallery}>
    <PaletteBar />
    <SectionDivider />
    <SectionHeader color="white" title="Gallery" />

    <Box paddingX={theme.space.viewportPaddingX}>
      <SectionDivider height="half" />
      <GallerySection
        gallery={gallery.ptero}
        title="Ptero"
      />
      <GalleryDivider />
      <GallerySection
        gallery={gallery.preact}
        title="Preact"
      />
    </Box>
    <SectionDivider height="half" />
  </Box>
);

Gallery.propTypes = {
  grid: PropTypes.object,
  preactInternalRoutes: PropTypes.object.isRequired,
  subtitle: PropTypes.object,
};
