import PropTypes from 'prop-types';
import React from 'react';
import {
  Box,
  Flex,
} from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';


export const AppNavLink = ({ navStyles, trailStyle, ...props }) => (
  <Flex
    alignItems="center"
    style={trailStyle}
  >
    <Box marginRight={navStyles.marginX}>
      <LinkInternal
        fontSize={navStyles.fontSize}
        paddingX={navStyles.paddingX}
        paddingY={navStyles.paddingY}
        {...props}
      />
    </Box>
  </Flex>
);

AppNavLink.propTypes = {
  navStyles: PropTypes.object.isRequired,
  trailStyle: PropTypes.object,
};
