import React from 'react';
import { routes } from 'lib/router/routes';
import { ArticleBody } from 'components/ArticleBody';
import { LinkInternal } from 'components/LinkInternal';


export const Intro = () => (
  <ArticleBody>
    <p>I joined Spotify through their acquisition of <LinkInternal to={routes.Preact}>Preact</LinkInternal>.</p>
    <p>At Spotify, I was the founding + lead designer and later acting product manager of a new team, <em>Insights & Experiments</em>. We made growth intelligence tools to help understand and influence the user lifecycle.</p>
  </ArticleBody>
);
