import PropTypes from 'prop-types';
import React from 'react';
import { Spring, Trail } from 'react-spring/renderprops';
import {
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';
import { theme } from 'lib/theme';
import { Box, Flex } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import stylesScroll from 'style/scroll.module.css';


const modalButtonDefaults = {
  active: {
    bg: 'linkInternal.bgActive',
    borderColor: 'transparent',
    color: 'linkInternal.colorActive',
  },
  hover: {
    bg: 'linkInternal.bgHover',
    borderColor: 'transparent',
    color: 'linkInternal.colorHover',
  },
  as: 'button',
  bg: 'transparent',
  borderColor: 'currentColor',
  color: 'white',
};

const ModalButton = ({ _active, _hover, bg, borderColor, color, href, icon, label, ...props }) => (
  <LinkInternal
    _active={{
      bg: (_active && _active.bg) || modalButtonDefaults.active.bg,
      color: (_active && _active.color) || modalButtonDefaults.active.color,
    }}
    _hover={{
      bg: (_hover && _hover.bg) || modalButtonDefaults.hover.bg,
      borderColor: 'transparent',
      color: (_hover && _hover.color) || modalButtonDefaults.hover.color,
    }}
    as={ !! href ? 'a' : 'button' }
    bg={ bg || modalButtonDefaults.bg }
    borderColor={ borderColor || modalButtonDefaults.borderColor }
    borderWidth="1px"
    color={ color || modalButtonDefaults.color }
    fontSize="xs"
    href={href}
    padding={2}
    target={ !! href ? '_blank' : null }
    {...props}
  >
    {!! icon &&
      <Icon marginRight={2} name={icon} size="0.8em" style={{ transform: 'translate3d(0.1em, -0.05em, 0)' }}/>
    }
    {label}
  </LinkInternal>
);

ModalButton.propTypes = {
  _active: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  _hover: PropTypes.shape({
    bg: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
  }),
  bg: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
};


export const ImageModal = ({ alt, isOpen, onClose, src }) => {
  const buttonsTrail = [
    <ModalButton
      href={src}
      icon="external-link"
      label="Full Size in New Window"
      target="_blank"
    />,
    <ModalButton
      _active={{
        bg: 'linkInternal.bgActive',
        color: 'linkInternal.colorActive',
      }}
      _hover={{
        bg: 'linkInternal.bgHover',
        color: 'linkInternal.colorHover',
      }}
      bg="scheme.secondary.darker"
      borderColor="transparent"
      icon="close"
      label="Close"
      marginLeft={4}
      onClick={onClose}
    />,
  ];
  return (
    <Modal
      closeOnOverlayClick={true} // this doesn't work as well as expected, so `onClick={onClose}` is also set on ModalOverlay
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="full"
    >
      <ModalOverlay
        cursor="zoom-out"
        onClick={onClose} // also set `<Modal closeOnOverlayClick>`
        style={{
          WebkitBackdropFilter: 'blur(5px)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Box
          backgroundImage={`
            radial-gradient(100% 100% at 50% 50%, ${theme.colors.dangerPalette.jet.darker}, ${theme.colors.dangerPalette.jet.base})
          `}
          bottom={0}
          left={0}
          opacity={0.85}
          position="fixed"
          right={0}
          top={0}
        />
      </ModalOverlay>
      <Spring
        config={{ mass: 0.5 }}
        from={{ opacity: 0.4 }}
        to={{ opacity: 1 }}
      >
        {springStyle => (<>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            position="fixed"
            right={4}
            style={springStyle}
            top={4}
            zIndex="max"
          >
            <Trail
              from={{ opacity: 0.25 }}
              items={buttonsTrail}
              keys={buttonsTrail.map((_, i) => i)}
              to={{ opacity: 1 }}
            >
              {button => trailStyle => (
                <Box children={button} style={trailStyle} />
              )}
            </Trail>
          </Flex>
          <ModalContent
            bg="transparent"
            boxShadow="none"
            style={springStyle}
          >
            <ModalBody
              paddingX={4}
              paddingY={2}
              textAlign="center"
            >
              <Box
                alignItems="center"
                borderRadius="md"
                className={stylesScroll.scrollbar}
                display="grid"
                height="100%"
                justifyContent="center"
                overflow="auto"
                paddingX={4}
              >
                <Spring
                  config={{ tension: 300 }}
                  from={{ transform: 'translate3d(0, 2%, 0)' } /* use percentage so larger images get bigger bounce */}
                  to={{ transform: 'translate3d(0, 0, 0)' }}
                >
                  {springSlideUpStyle => (
                    <Image
                      alt={alt}
                      maxWidth="100%"
                      src={src}
                      style={springSlideUpStyle}
                    />
                  )}
                </Spring>
              </Box>
            </ModalBody>
          </ModalContent>
        </>)}
      </Spring>
    </Modal>
  );
};

ImageModal.propTypes = {
  alt: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  src: PropTypes.string,
};
