import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@chakra-ui/core';
import { PseudoBox } from 'components/Layout';

export const CloseButton = ({ closeFunc, isReversed, ...props }) => (
  <PseudoBox
    _active={{
      bg: 'scheme.secondary.lighter',
      borderColor: 'transparent',
      color: 'scheme.secondary.darker',
    }}
    _hover={{
      bg: 'white',
      borderColor: 'currentColor',
      color: 'scheme.secondary.dark',
    }}
    as="button"
    bg={`${!! isReversed ? 'white' : 'black' }Alpha.100`}
    border="1px solid transparent"
    borderRadius="xl"
    children={ <Icon name="close" /> }
    color={`${!! isReversed ? 'white' : 'black' }Alpha.700`}
    fontSize="2xs"
    onClick={closeFunc}
    padding={2}
    position="absolute"
    right={3}
    top={3}
    {...props}
  />
);

CloseButton.propTypes = {
  closeFunc: PropTypes.func,
  isReversed: PropTypes.bool,
};
