import PropTypes from 'prop-types';
import React from 'react';
import Color from 'color';
import { useInView } from 'react-intersection-observer';
import { Spring } from 'react-spring/renderprops';
import { theme } from 'lib/theme';
import {
  Box,
  PseudoBox,
} from 'components/Layout';


export const LayerCard = ({ cardCount, children, indexPlusOne, ...props }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0.3,
    triggerOnce: false,
  });

  let isCenterLeft, isCenterRight, isLeft, isCenter; /*isRight*/
  if (cardCount === 1) {
    isCenter = true;
  } else if (cardCount === 2) {
    isCenterLeft = indexPlusOne === 1;
    isCenterRight = indexPlusOne === 2;
  } else if (cardCount === 3) {
    isLeft = indexPlusOne === 1;
    isCenter = indexPlusOne === 2;
    // isRight = indexPlusOne === 3;
  }
  return (
    <PseudoBox
      _before={{
        backgroundImage: cardCount === 3 && ! isCenter && `linear-gradient(to ${!! isLeft ? 'right' : 'left'}, ${theme.colors.dangerPalette.jet.base} 5%, ${Color(theme.colors.dangerPalette.jet.base).fade(0.9999).string()} 70%)`,
        bottom: 0,
        content: '""',
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 'float',
      }}
      display={{
        base: cardCount === 3 && ! isCenter && 'none',
        lg: 'block',
      }}
      gridColumn={{
        base: 'a1 / a55',
        lg: cardCount === 3 ? 'span 4'
          : isCenterLeft ? 'a1 / a3b2'
            : isCenterRight ? 'a3b2 / a5'
              : 'b1 / b3'
      }}
      gridRow={1}
      opacity={cardCount === 3 && ! isCenter && 0.2}
      padding={1}
      position="relative"
      ref={intersectionObserverRef}
      textAlign="center"
      {...props}
    >
      <Spring
        to={{
          opacity: !! inView ? 1 : 0,
          visibility: !! inView ? 'visible' : 'hidden',
        }}
      >
        { springStyle => (
          <Box
            bg="white"
            borderRadius="sm"
            boxShadow={2}
            fontSize="xs"
            fontWeight={ !! isCenter && 'medium' }
            lineHeight="base"
            padding={{
              base: 2,
              lg: 4
            }}
            style={springStyle}
            textAlign="center"
          >
            { children }
          </Box>
        )}
      </Spring>
    </PseudoBox>
  );
};

LayerCard.propTypes = {
  cardCount: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  indexPlusOne: PropTypes.number.isRequired,
};
