import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'lib/helmet';
import { getJob } from 'lib/helpers';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { ArticleBody } from 'components/ArticleBody';
import { ArticleHeader } from 'components/ArticleHeader';
import { ArticleImage } from 'components/ArticleImage';
import { Aside } from 'components/Aside';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { LinkExternal } from 'components/LinkExternal';
import { SectionHeader } from 'components/SectionHeader';
import { SubtitleDecorated } from 'components/Typography';
import { Video } from 'components/Video';

import { rafterInternalRoutes } from './rafterInternalRoutes';
import { Gallery } from './Gallery';


const job = getJob('Rafter');

const assetPath = '/assets/portfolio/rafter/';

export const Rafter = () => (<>
  <Helmet>
    <title>{pageTitle(job.org)}</title>
  </Helmet>
  <Box as="article">
    <ArticleHeader
      titlePrimary={job.org}
      titleSecondary={job.title}
      titleTertiary={
        `${(!! job.tenureStart && job.tenureStart !== job.tenureEnd) ? job.tenureStart + ' – ' : ''}${job.tenureEnd}`
      }
    />
    <ArticleBody>
      <p>I was the first design hire at Rafter from Series A through C.</p>
      <p>Working as both an IC and design lead, I was responsible for optimizing the company’s existing student-focused textbook rental ecommerce and designing new products for an expanded audience:</p>
      <ul>
        <li>Whitelabel ecommerce, inventory management, and CRM deployed by college bookstores</li>
        <li>In-store point of sale, self-checkout, price comparison, and order fullfilment (<LinkExternal href="https://patents.google.com/patent/US20130179227">Patent US20130179227</LinkExternal>)</li>
      </ul>

      <SectionDivider height="half" />

      <SectionDivider id={rafterInternalRoutes.inStore} />
      <SectionHeader colorDecoration="scheme.secondary.dark" title="In-Store Services" />
      <SubtitleDecorated>Background</SubtitleDecorated>
      <p>When I joined the company in late 2009, Rafter (then called Bookrenter) did not have a platform for college bookstores. We exclusively sold direct to students through our own domain.</p>
      <p>By 2011, about 1 out of every 4 college bookstores used our white label offerings to rent textbooks online or in-store (~500 stores in the US). And it <em>was</em> 2011, so skeumorphism still ruled the world.</p>
      <p>I was responsible for the design of all of our in-store tools, including point of sale, online order pickup, rental returns, customer self-checkout, and some elements of inventory management.</p>
      <Aside isPadded placement="wide">
        <Video src={`${assetPath}inStore/video-pos-endToEnd.mp4`} />
      </Aside>

      <SectionDivider height="half" />
      <SubtitleDecorated>A simple experience for seasonal employees</SubtitleDecorated>
      <p>Initial design goals were modest - the product offered inventory flexibility for budget-conscious stores. If it worked it would be good enough.</p>
      <p>But while I was researching on-site, something became obvious – bookstores hired an enourmous number of employees for the beginning of each frantic school term, employed them for three weeks while lines were long, and then never saw them again. It's like Christmas at the mall, but in half the time and none of the purchases are discretionary. Minimal staff training was an absolute requirement.</p>
      <p>I prioritized a design solution that leaned on a small set of familiar interaction patterns and an approachable, low-density aesthetic.</p>
      <p>I worked with a talented illustrator to develop a cast of confident characters depicting the checkout and rental return processes, helping guide both customers and cashiers through each step.</p>
      <p>In addition to being the sole product designer, I was the primary frontend developer on this project, fully responsible for the responsive design approach and implementation (also a relatively new thing in 2011).</p>
      <Aside isPadded placement="wide">
        <Gridset alignItems="center">
          <Box gridColumn="span 4" paddingX={2}>
            <ArticleImage
              alt="Illustration for in-store checkout."
              bg="white"
              modalSrc={`${assetPath}inStore/illustration-checkout_landing_blue.png`}
              src={`${assetPath}inStore/illustration-checkout_landing_blue.png`}
            />
          </Box>
          <Box gridColumn="span 4" paddingX={2}>
            <ArticleImage
              alt="Illustration for in-store checkout."
              modalSrc={`${assetPath}inStore/illustration-selfCheckout.png`}
              src={`${assetPath}inStore/illustration-selfCheckout.png`}
            />
          </Box>
          <Box gridColumn="span 4" paddingX={2}>
            <ArticleImage
              alt="Illustration for in-store checkout."
              bg="white"
              modalSrc={`${assetPath}inStore/illustration-pickup_orange.png`}
              src={`${assetPath}inStore/illustration-pickup_orange.png`}
            />
          </Box>
        </Gridset>
      </Aside>

      <SectionDivider height="half" />
      <Aside isPadded placement="right">
        <Video
          loop
          src={`${assetPath}inStore/video-pos-responsiveDesign.mp4`}
        />
      </Aside>
      <SubtitleDecorated>Mobile First</SubtitleDecorated>
      <p>I led frontend development on a <q>mobile first</q> approach, shipping responsive design to sales floors before Ethan Marcotte’s <LinkExternal href="https://abookapart.com/products/responsive-web-design">seminal book</LinkExternal> was published.</p>
      <p>The mobile-friendly design enabled provisioning stores with iPod Touch devices using <LinkExternal href="https://lineapro.store/products/linea-pro-scanners/">LineaPro</LinkExternal> barcode and credit card scanners, allowing cashiers to reduce congestion at the register and check out customers from the sales floor. At the time, this was a novel experience not often seen outside of Apple stores.</p>

      <SectionDivider height="half" />
      <Aside isPadded placement="right" position="relative" zIndex="float">
        <ArticleImage
          alt="Focus group with college students."
          modalSrc={`${assetPath}inStore/focus-group.jpg`}
          src={`${assetPath}inStore/focus-group.jpg`}
        />
      </Aside>
      <Aside isPadded placement="right" transform={{ [LAYOUT_SWITCH]: 'translate3d(0, 1rem, 0)' }}>
        <ArticleImage
          alt="Dogfooding self-checkout with a faux in-office bookstore."
          modalSrc={`${assetPath}inStore/faux-collegeStore.jpg`}
          src={`${assetPath}inStore/faux-collegeStore.jpg`}
        />
      </Aside>
      <SubtitleDecorated>Design Research</SubtitleDecorated>
      <ul>
        <li>On-site visits to college bookstores to observe purchasing patterns and interview employees.</li>
        <li>Focus groups with students.</li>
        <li>Dogfooding internally with a faux in-office bookstore (pictured).</li>
        <li>Learnings from our existing B2C ecommerce.</li>
      </ul>

      <SectionDivider height="half" />
      <Aside isPadded placement="narrow">
        <ArticleImage
          alt="Workflow for in-store point of sale."
          modalSrc={`${assetPath}inStore/pos-workflow.png`}
          src={`${assetPath}inStore/pos-workflow.png`}
        />
      </Aside>

      <SectionDivider height="half" />
      <SubtitleDecorated>Opportunities & Constraints</SubtitleDecorated>
      <ul>
        <li>Bringing an existing online rental service to physical stores had unique challenges, such as a mixed customer base of new & returning users. Rather than asking customers for account passwords while they stood in line (user accounts were a fixed requirement for online customer service and rental returns), we used email addresses with school-issued photo IDs to authenticate new rentals for existing accounts. In later iterations, we supported schools that did not issue photo IDs by using phone number and email address to authenticate.</li>
        <Aside isPadded placement="right">
          <ArticleImage
            alt="Focus group with college students."
            modalSrc={`${assetPath}inStore/pos-whiteboard.jpg`}
            src={`${assetPath}inStore/pos-whiteboard.jpg`}
          />
        </Aside>
        <li><q>Showrooming</q> negatively impacted college bookstores significantly above the industry average. By providing in-store dynamic pricing, we reduced the appeal for customers to use the bookshelf only to confirm their book list before ordering from Amazon, et al.</li>
        <li>This iteration focused on in-store textbook rentals only. We later introduced additional inventory types, mixed online/in-store orders, and affiliate commisions through price comparison & instant online orders fulfilled by 3rd party vendors.</li>
      </ul>

      <SectionDivider height="half" />
      <SubtitleDecorated>Outcomes</SubtitleDecorated>
      <ul>
        <li>Surveys by NACS (National Association of College Stores) indicated students at our colleges were 45% more likely year over year to get materials from their college store after adopting Rafter’s in-store tools.</li>
        <li>Stores expanding their rental offering from online-only to in-store experienced over 100% YoY rental growth despite growing competition from online marketplaces.</li>
        <li>Significant revenue in first quarter of limited deployment.</li>
        <li>Increased store foot traffic led to significant store gains in their primary profit categories (spirit wear, food & beverage, gifts, school supplies).</li>
      </ul>
    </ArticleBody>
    <SectionDivider />
    <Gallery rafterInternalRoutes={rafterInternalRoutes} />
  </Box>
</>);
