import PropTypes from 'prop-types';
import React from 'react';
import {
  LAYOUT_SWITCH,
  getBreakpoint,
  useMediaQuery,
} from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import {
  Box,
  Flex,
} from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';


const NavItem = ({ ...props }) => (
  <Box
    padding={1}
  >
    <LinkInternal
      bg="transparent"
      fontSize={{
        base: '2xs',
        lg: 'xs',
      }}
      paddingX={2}
      paddingY={2}
      {...props}
    />
  </Box>
);

export const ProjectNav = ({ isSticky, spotifyInternalRoutes }) => {
  const currentBreakpoint = useMediaQuery();
  const breakpoint = getBreakpoint(currentBreakpoint);

  return (<>
    <Gridset
      backgroundImage={`
        linear-gradient(to right, rgba(255,255,255, 0.001), rgba(255,255,255, 0.7), rgba(255,255,255, 0.001))
      `}
      bg="whiteAlpha.800"
      borderBottomColor="app.divider"
      borderBottomWidth={1}
      paddingX={theme.space.viewportPaddingX}
      position={{
        base: 'unset',
        [LAYOUT_SWITCH]: !! isSticky && 'sticky',
      }}
      style={{
        WebkitBackdropFilter: 'blur(10px)',
        backdropFilter: 'blur(10px)',
      }}
      top="2.9rem"
      zIndex={theme.zIndices.sticky - 1} // position below the spotify ProjectNav
    >
      <Flex
        alignItems="center"
        flexWrap="wrap"
        gridColumn="span 12"
        justifyContent="center"
      >
        { !! breakpoint.large &&
          <NavItem
            children="Background"
            href={`#${spotifyInternalRoutes.background}`}
          />
        }
        <NavItem
          children="Hypothesis-Driven Design"
          href={`#${spotifyInternalRoutes.thoughtfulExecution}`}
        />
        <NavItem
          children="Discovery"
          href={`#${spotifyInternalRoutes.discovery}`}
        />
        <Flex
          // similar to Nbsp, prevent widowed last item
          alignItems="center"
          justifyContent="center"
        >
          <NavItem
            children="Exploration & Feedback"
            href={`#${spotifyInternalRoutes.exploration}`}
          />
          <NavItem
            children="Outcomes"
            href={`#${spotifyInternalRoutes.outcomes}`}
          />
        </Flex>
      </Flex>
    </Gridset>
  </>);
};

ProjectNav.propTypes = {
  isSticky: PropTypes.bool,
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
