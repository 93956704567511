import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';


export const Video = ({ src, type = 'video/mp4', ...props }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0.6,
    triggerOnce: false,
  });

  const videoRef = useRef(null);
  useEffect(() => {
    const video = videoRef.current;
    if (!! inView) {
      video.play();
    } else if (! inView) {
      video.pause();
    };
  }, [videoRef, inView]);

  return (
    <figure ref={intersectionObserverRef}>
      <video
        autobuffer="autobuffer"
        controls
        controlsList="nodownload"
        muted
        playsInline
        preload="auto"
        ref={videoRef}
        style={{ maxWidth: '100%' }}
        {...props}
      >
        <source
          src={src}
          type={type}
        />
      </video>
    </figure>
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
};
