import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Spring } from 'react-spring/renderprops';
import {
  Box,
} from 'components/Layout';


export const Decoration = ({ color, placement, triggerOnce = true, ...props }) => {
  const header = placement === 'header';
  const height = {
    content: '2px',
    header: '5px',
  };

  const [intersectionObserverRef, inView] = useInView({
    threshold: 0,
    triggerOnce // TODO - evaluate performance
  });

  return (
    <Box
      height={ !! header ? height.header : height.content }
      marginY={ !! header ? 4 : 2 }
      ref={intersectionObserverRef}
    >
      { !! inView &&
        <Spring
          delay={ 200 }
          from={{
            opacity: 0,
            transform: !! header ?
              'scaleX(0.2) translate3d(-75vw, 0, 0)'
              : 'scaleX(0.5) translate3d(0, 0, 0)',
          }}
          to={{
            opacity: 1,
            transform: 'scaleX(1) translate3d(0, 0, 0)',
          }}
        >
          {springStyle => (
            <Box
              bg={ !! color ? color : 'scheme.primary.dark' }
              borderRadius="md"
              height={!! header ? height.header : height.content }
              style={springStyle}
              transformOrigin="left center"
              width={!! header ? 16 : 6}
              {...props}
            />
          )}
        </Spring>
      }
    </Box>
  );
};

Decoration.propTypes = {
  color: PropTypes.string,
  placement: PropTypes.oneOf(['content', 'header']).isRequired,
  triggerOnce: PropTypes.bool,
};
