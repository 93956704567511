import React from 'react';
import { theme } from 'lib/theme';
import {
  Box,
  Flex,
} from 'components/Layout';
import { Text } from 'components/Typography';

const colorNames = ['eggshell', 'melon', 'peach', 'flax', 'turquoise', 'silverLake', 'purple', 'jet', 'grayscale'];
const colorKeywords = ['lighter', 'light', 'base', 'dark', 'darker'];
const colorTints = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

export const Palette = () => colorNames.map((color) => {
  const colorValue = `dangerPalette.${color}`;
  return (
    <Flex
      alignItems="center"
      as="section"
      bg={`${colorValue}.base`}
      flexWrap="wrap"
      justify="space-between"
      key={color}
      paddingX={theme.space.viewportPaddingX}
      width="100%"
    >
      <Text
        as="h1"
        color="white"
        flexGrow={ 1 }
        fontWeight="bold"
        paddingRight={8}
        paddingY={2}
        textTransform="capitalize"
      >
        {color}
      </Text>
      <Flex
        justify="stretch"
      >
        { colorKeywords.map((keyword) => (
          <Box bg={`${colorValue}.${keyword}`} border="0.25em" borderColor="transparent" borderStyle="solid" h={12} key={keyword} w={12} />
        ))}
      </Flex>
      <Flex
        alignSelf="stretch"
        flexDirection="column"
        paddingLeft="gutterOutside"
        paddingY={2}
      >
        { colorTints.map((tint) => (
          <Box bg={`${colorValue}.${tint}`} flexGrow={1} h={2} key={tint} w={16} />
        ))}
      </Flex>
    </Flex>
  );
});
