import React, { forwardRef } from 'react';
import { Grid } from 'components/Layout';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme, wireframeStyle } from 'lib/theme';


/*
  Gridset: a compound 4-6 grid (with half-6s for convenience).

  -------------------------------------
  |   4    |   4    |   4    |    4   |
  |  6  |  6  |  6  |  6  |  6  |  6  |
  |  |                             |  |
  -------------------------------------
*/

const gridset = theme.sizes.gridset;
const baseColWidth = 100 / 12 + '%';

export const Gridset = forwardRef((props, ref) => (
  <Grid
    {...props}
    gridColumnGap={gridset.gutter}
    gridTemplateColumns={{
      base: `
        [a0b0]
        ${baseColWidth} [a05]
        ${baseColWidth} [a1]
        ${baseColWidth} [b1]
        ${baseColWidth} [a2]
        ${baseColWidth} [a25]
        ${baseColWidth} [a3b2]
        ${baseColWidth} [a35]
        ${baseColWidth} [a4]
        ${baseColWidth} [b3]
        ${baseColWidth} [a5]
        ${baseColWidth} [a55]
        ${baseColWidth} [a6b4]
      `,
      [LAYOUT_SWITCH]: `
        [a0b0]
        ${0.5 * parseFloat(gridset.col6)}% [a05]
        ${0.5 * parseFloat(gridset.col6)}% [a1]
        ${parseFloat(gridset.col4) - parseFloat(gridset.col6) - parseFloat(gridset.gutter)}% [b1]
        ${parseFloat(gridset.col4) - parseFloat(gridset.col6) - parseFloat(gridset.gutter)}% [a2]
        ${0.5 * parseFloat(gridset.col6)}% [a25]
        ${0.5 * parseFloat(gridset.col6)}% [a3b2]
        ${0.5 * parseFloat(gridset.col6)}% [a35]
        ${0.5 * parseFloat(gridset.col6)}% [a4]
        ${parseFloat(gridset.col4) - parseFloat(gridset.col6) - parseFloat(gridset.gutter)}% [b3]
        ${parseFloat(gridset.col4) - parseFloat(gridset.col6) - parseFloat(gridset.gutter)}% [a5]
        ${0.5 * parseFloat(gridset.col6)}% [a55]
        ${0.5 * parseFloat(gridset.col6)}% [a6b4]
      `
    }}
    ref={ref}
    style={{
      ...wireframeStyle(props),
    }}
  />
));
