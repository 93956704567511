import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Spring } from 'react-spring/renderprops';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { Box } from 'components/Layout';


export const Quote = ({ attribution, attributionColor, color, quote, springDelay, ...props }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });
  return (
    <Box
      alignSelf="center"
      gridColumn={{
        base: 'span 12',
        [LAYOUT_SWITCH]: 'span 4',
      }}
      ref={intersectionObserverRef}
    >
      <Spring
        config={{ delay: springDelay }}
        to={{
          opacity: !! inView ? 1 : 0,
          transform: !! inView ? 'scale(1) translateY(0)' : 'scale(0.9) translateY(2rem)',
          visibility: !! inView ? 'visible' : 'hidden',
        }}
      >
        {springStyle => (
          <Box
            color={color}
            lineHeight="base"
            paddingX={{
              base: 4,
            }}
            paddingY={4}
            style={springStyle}
            textAlign="center"
            {...props}
          >
            <Box
              as="blockquote"
              color={attributionColor}
              fontFamily="header"
              fontSize="lg"
              fontWeight="medium"
              paddingBottom={2}
              style={{ textWrap: 'balance' }}
            >
              “{quote}”
            </Box>
            { !! attribution && <Box fontSize="sm">– {attribution}</Box> }
          </Box>
        )}
      </Spring>
    </Box>
  );
};

Quote.propTypes = {
  attribution: PropTypes.string,
  attributionColor: PropTypes.string,
  color: PropTypes.string.isRequired,
  quote: PropTypes.string,
  springDelay: PropTypes.number,
};
