import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { routes } from 'lib/router/routes';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { AppNavBrand } from './AppNavBrand';
import { AppNavContact } from './AppNavContact';
import { AppNavPortfolio } from './AppNavPortfolio';

const navStyles = {
  fontSize: 'xs',
  paddingX: 2,
  paddingY: 2,
  marginX: 2,
};

export const AppNav = ({ loggedIn }) => {
  const { location } = useContext(__RouterContext);
  const atHomePage = (location.pathname.toLowerCase() === routes.Home.toLowerCase());

  return (
    <Gridset
      alignItems="center"
      alignSelf="center"
      as="nav"
      bg={{ base: ! atHomePage && 'white', [LAYOUT_SWITCH]: 'transparent' }}
      borderBottomWidth={{ base: ! atHomePage && 1, [LAYOUT_SWITCH]: 0 }}
      borderColor={{ base: 'app.divider', [LAYOUT_SWITCH]: 'transparent' }}
      display={{ base: 'flex', [LAYOUT_SWITCH]: 'grid' }}
      justifyItems={{ base: 'flex-start', [LAYOUT_SWITCH]: 'start' }}
      paddingX={theme.space.viewportPaddingX}
      paddingY={2}
      position={{ base: ! atHomePage && 'fixed', [LAYOUT_SWITCH]: 'relative' }}
      top={0}
      width="100%"
      zIndex="sticky">
      <AppNavBrand atHomePage={atHomePage} navStyles={navStyles} />
      <AppNavPortfolio atHomePage={atHomePage} loggedIn={loggedIn} navStyles={navStyles} />
      <AppNavContact atHomePage={atHomePage} navStyles={navStyles} />
    </Gridset>
  );
};

AppNav.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};
