import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const DynamicScrollToTop = (props) => {
  useEffect(() => {
    // console.log(props);

    /*
      Scroll to top on route change, with exceptions.

      Preserve scroll position when:
      - used back button (history)
      - clicked on a link that programmatically calls `history.goBack()`
      - manually changed the URL in the address bar

      Allows scrolling to anchor #hash.
    */

    // don't scroll to top for:
    if (props.history.action === 'POP') return;

    // do scroll to top if not using #anchor hash (don't interfere with hash targets, let the browser handle them):
    const hash = props.location.hash;
    if (! hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  });

  return null;
};

export const ScrollToTop = withRouter(DynamicScrollToTop);
