import React, { forwardRef } from 'react';
import {
  Box as ChakraBox,
  Flex as ChakraFlex,
  Grid as ChakraGrid,
  PseudoBox as ChakraPseudoBox,
} from '@chakra-ui/core';
import { wireframeStyle } from 'lib/theme';


export const Box = forwardRef((props, ref) => (
  <ChakraBox {...props} ref={ref} style={{ ...wireframeStyle(props) }} />
));

export const Flex = forwardRef((props, ref) => (
  <ChakraFlex {...props} ref={ref} style={{ ...wireframeStyle(props) }} />
));

export const Grid = forwardRef((props, ref) => (
  <ChakraGrid {...props} ref={ref} style={{ ...wireframeStyle(props) }} />
));

export const PseudoBox = forwardRef((props, ref) => (
  <ChakraPseudoBox {...props} ref={ref} style={{ ...wireframeStyle(props) }} />
));

