import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { SectionDivider } from 'components/Divider';
import { Box } from 'components/Layout';
import { PaletteBar } from 'components/PaletteBar';

import { ProjectBackground } from './ProjectBackground';
import { ProjectDiscovery } from './ProjectDiscovery';
import { ProjectExploration } from './ProjectExploration';
import { ProjectGallery } from './ProjectGallery';
import { ProjectIntro } from './ProjectIntro';
import { ProjectNav } from './ProjectNav';
import { ProjectOutcomes } from './ProjectOutcomes';
import { ProjectThoughtfulExecution } from './ProjectThoughtfulExecution';
import { SectionHeader } from 'components/SectionHeader';

const introBgColor = 'blue.dark';

export const Project = ({ spotifyInternalRoutes }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  return (<>
    <PaletteBar id={spotifyInternalRoutes.project} />
    <Box
      backgroundImage={`
        linear-gradient(to right, rgba(255,255,255, 0.001), rgba(255,255,255, 0.7), rgba(255,255,255, 0.001))
      `}
      bg="whiteAlpha.800"
    >
      <SectionDivider  />
      <SectionHeader colorDecoration={introBgColor} title="The Work" />
      <SectionDivider height="half" />
    </Box>
    <Box
      ref={intersectionObserverRef}
    >
      <ProjectNav isSticky={inView} spotifyInternalRoutes={spotifyInternalRoutes} />
      <Box
        bg={introBgColor}
        paddingBottom={8}
      >
        <PaletteBar />
        <ProjectIntro />
      </Box>
      <ProjectBackground spotifyInternalRoutes={spotifyInternalRoutes} />
      <ProjectThoughtfulExecution spotifyInternalRoutes={spotifyInternalRoutes} />
      <ProjectDiscovery spotifyInternalRoutes={spotifyInternalRoutes} />
      <ProjectExploration spotifyInternalRoutes={spotifyInternalRoutes} />
      <ProjectOutcomes spotifyInternalRoutes={spotifyInternalRoutes} />
      <SectionDivider />
    </Box>
    <ProjectGallery spotifyInternalRoutes={spotifyInternalRoutes} />
  </>);
};

Project.propTypes = {
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
