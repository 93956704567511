import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@chakra-ui/core';
import { Decoration } from 'components/Decoration';
import { Box, Flex } from 'components/Layout';
import {
  Subtitle,
  Header,
} from 'components/Typography';


export const OverviewItem = ({ children, color, header, icon, title, ...props }) => (
  <Flex
    alignItems="stretch"
    alignSelf="stretch"
    paddingX={{
      base: 4,
      md: 8,
    }}
    paddingY={{
      base: 8,
      md: 12,
      lg: 16,
    }}
    position="relative"
    {...props}
  >
    <div>
      {!! icon && <Icon name={icon} size="1rem" /> }
      <Header
        children={header}
        color={color || 'scheme.secondary.base'}
        textTransform="uppercase"
      />
      <Subtitle
        children={title}
        fontSize={{
          base: null,
          lg: '2xl',
        }}
        paddingBottom={{ base: 2, md: 4 }}
        paddingTop={{ base: 0, md: 1 }}
      />
      <Decoration color={color || 'scheme.secondary.base'} placement="content" />
      <Box paddingY={4}>
        {children}
      </Box>
    </div>
  </Flex>
);

OverviewItem.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};
