import React from 'react';
import { Box } from 'components/Layout';
import { CardContentHeader } from 'components/Card';
import {
  BodyAlt,
} from 'components/Typography';
import { Persona } from './Persona';

const Header = ({ ...props }) => (
  <CardContentHeader marginBottom="-0.75rem" {...props} />
);

export const AudiencePersonas = () => (<>
  <Persona
    avatarSrc="/assets/symbols/woman.svg"
    colorHeaderNoTint="portfolio.spotify.persona.maven"
    description={
      <div><em>Data Mavens</em> analyze and interpret data sets.</div>
    }
    imageSVGViewBox="0 0 310 310"
    title="Data Mavens"
  >
    <Header color="portfolio.spotify.persona.maven.base">Goals</Header>
    <BodyAlt as="div" paddingBottom={4}>
      <Box className="typographyEnabled">
        <Box as="ul" fontSize="xs">
          <li>Provide a holistic, accurate, and relevant view of user behavior to inform product decisions.</li>
          <li>Define, run, and interpret product experiments.</li>
        </Box>
      </Box>
    </BodyAlt>
    <Header color="portfolio.spotify.persona.maven.base">Pain Points</Header>
    <Box className="typographyEnabled">
      <Box as="ul" fontSize="xs">
        <li>Lack of labelled event data requires significant effort to interpret and communicate results.</li>
        <li>Bandwidth limited by large volume of potentially automatable inquiries.</li>
        <li>Inconsistent definitions for engagement metrics and user segments resulted in redundant and contradictory work across teams.</li>
      </Box>
    </Box>
  </Persona>

  <Persona
    avatarSrc="/assets/symbols/man.svg"
    colorHeaderNoTint="portfolio.spotify.persona.maker"
    description={
      <div><em>Product Makers</em> use insights to inform new capabilities and improve the user experience.</div>
    }
    imageSVGViewBox="0 0 310 310"
    title="Product Makers"
  >
    <Header color="portfolio.spotify.persona.maker.base">Goals</Header>
    <BodyAlt as="div" paddingBottom={4}>
      <Box className="typographyEnabled">
        <Box as="ul" fontSize="xs">
          <li>Effectively prioritize work based on potential impact.</li>
          <li>Roll out product changes to large audiences with confidence.</li>
          <li>Consistently ship high-quality product features that measurably improve human-centered outcomes.</li>
        </Box>
      </Box>
    </BodyAlt>
    <Header color="portfolio.spotify.persona.maker.base">Pain Points</Header>
    <Box className="typographyEnabled">
      <Box as="ul" fontSize="xs">
        <li>Lack visibility into key drivers of user outcomes when not engaging time-consuming research by Data Mavens.</li>
        <li>In a queue with other Product Makers for Data Maven insight and analysis resources.</li>
        <li>Running product experiments can be a significant time investment and often returns inconclusive results.</li>
      </Box>
    </Box>
  </Persona>
</>);
