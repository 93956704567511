import Color from 'color';

export let palette = {
  eggshell: {
    base: 'hsl(30, 6%, 95%)', // # f3f2f1
    lighter: 'hsl(0, 0%, 100%)',
    light: 'hsl(0, 0%, 100%)',
    dark: 'hsl(30, 3%, 77%)',
    darker: 'hsl(27, 6%, 61%)',
  },
  grayscale: {
    base: 'hsl(0, 0%, 50%)',
  },
  jet: {
    base: 'hsl(240, 20%, 24%)', // # 2A2A3D
    lighter: 'hsl(240, 12%, 45%)',
    light: 'hsl(240, 16%, 34%)',
    dark: 'hsl(240, 22%, 17%)',
    darker: 'hsl(240, 36%, 12%)',
  },
  flax: {
    base: 'hsl(40, 100%, 76%)', // # ffd685
    lighter: 'hsl(40, 100%, 90%)',
    light: 'hsl(40, 100%, 83%)',
    dark: 'hsl(40, 87%, 67%)',
    darker: 'hsl(37, 52%, 47%)',
  },
  melon: {
    base: 'hsl(1, 100%, 76%)', // # ff8785
    lighter: 'hsl(1, 100%, 90%)',
    light: 'hsl(1, 100%, 83%)',
    dark: 'hsl(2, 87%, 67%)',
    darker: 'hsl(359, 57%, 45%)',
  },
  peach: {
    base: 'hsl(28, 100%, 76%)', // # ffbe85
    lighter: 'hsl(27, 100%, 90%)',
    light: 'hsl(28, 100%, 83%)',
    dark: 'hsl(28, 87%, 67%)',
    darker: 'hsl(26, 54%, 46%)',
  },
  silverLake: {
    base: 'hsl(204, 89%, 69%)', // # 6abef6
    lighter: 'hsl(205, 93%, 89%)',
    light: 'hsl(205, 86%, 77%)',
    dark: 'hsl(205, 77%, 59%)',
    darker: 'hsl(205, 73%, 48%)',
  },
  purple: {
    base: 'hsl(249, 28%, 55%)', // # 766cac
    lighter: 'hsl(247, 33%, 84%)',
    light: 'hsl(248, 31%, 70%)',
    dark: 'hsl(248, 32%, 43%)',
    darker: 'hsl(247, 42%, 33%)',
  },
  turquoise: {
    base: 'hsl(183, 38%, 55%)', // # 61b3b8
    lighter: 'hsl(183, 59%, 85%)',
    light: 'hsl(183, 47%, 70%)',
    dark: 'hsl(183, 43%, 40%)',
    darker: 'hsl(182, 50%, 34%)',
  },
  portfolio: {
    spotify: 'hsl(141, 73%, 42%)',
  }
};

(function setPaletteTints() {
  Object.keys(palette).forEach(color => {
    const base = palette[color].base;
    palette[color] = {
      ...palette[color],
      0: 'white',
      50: Color(base).mix(Color('white'), 0.9).hsl().string(),
      100: Color(base).mix(Color('white'), 0.8).hsl().string(),
      200: Color(base).mix(Color('white'), 0.6).hsl().string(),
      300: Color(base).mix(Color('white'), 0.4).hsl().string(),
      400: Color(base).mix(Color('white'), 0.2).hsl().string(),
      500: palette[color].base,
      600: Color(base).mix(Color('black'), 0.2).hsl().string(),
      700: Color(base).mix(Color('black'), 0.4).hsl().string(),
      800: Color(base).mix(Color('black'), 0.6).hsl().string(),
      900: Color(base).mix(Color('black'), 0.8).hsl().string(),
      1000: 'black',
    };
  });
})();

(function setPaletteSpecials() {
  palette = {
    ...palette,
    black: 'hsl(0, 0%, 0%)',
    white: 'hsl(0, 0%, 100%)',
    currentColor: 'currentColor',
    transparent: 'transparent',
    inherit: 'inherit',
    initial: 'initial',
    unset: 'unset',
  };
})();
