export const jobDescriptions = [
  {
    description: '…',
    highlight: true,
    logo: '/assets/portfolio/strongdm/strongdm-logo-symbol-white.png',
    org: 'StrongDM',
    outcome: null,
    hasPortfolioPage: true,
    portfolioRoute: '/portfolio/strongdm',
    tenureEnd: 2023,
    tenureStart: 2020,
    title: 'Head of Product Design',
  },
  {
    description: 'Primary designer for a set of growth intelligence tools to increase user engagement, conversion, and retention.',
    highlight: true,
    logo: '/assets/portfolio/spotify/spotifyLogo-green.png',
    org: 'Spotify',
    outcome: null,
    hasPortfolioPage: true,
    portfolioRoute: '/portfolio/spotify',
    tenureEnd: 2019,
    tenureStart: 2016,
    title: 'Product Designer, Acting Product Manager',
  },
  {
    description: 'Led product design for customer success management and behavorial analytics to reduce churn, drive upsell, and discover key user behaviors.',
    highlight: false,
    logo: '/assets/portfolio/preact/preactLogo.png',
    org: 'Preact',
    outcome: 'acquired by Spotify',
    hasPortfolioPage: true,
    portfolioRoute: '/portfolio/preact',
    tenureEnd: 2016,
    tenureStart: 2014,
    title: 'Head of Product Design',
  },
  {
    description: 'Solo designer for legal client management platform and legal services marketplace.',
    highlight: false,
    logo: '/assets/portfolio/lawgives/lawgivesLogo.png',
    org: 'LawGives',
    outcome: null,
    hasPortfolioPage: false,
    portfolioRoute: '/portfolio/lawgives',
    tenureEnd: 2014,
    tenureStart: 2013,
    title: 'Lead Designer',
  },
  {
    description: 'First design hire and lead product designer through Series A → C. I designed college course material management, in-store point of sale, and whitelabel ecommerce.',
    highlight: false,
    logo: '/assets/portfolio/rafter/rafterLogo.png',
    logoReversed: '/assets/portfolio/rafter/rafterLogo-reversed.png',
    org: 'Rafter',
    outcome: null,
    hasPortfolioPage: true,
    portfolioRoute: '/portfolio/rafter',
    tenureEnd: 2012,
    tenureStart: 2009,
    title: 'Lead Product Designer',
  },
  {
    description: 'Developed web interfaces for engaging social media marketing campaigns for brands including Cosmo, Ogilvy, Walmart, and Kodak.',
    highlight: false,
    logo: null,
    org: 'Zazengo',
    outcome: null,
    hasPortfolioPage: false,
    portfolioRoute: '/portfolio/zazengo',
    tenureEnd: 2009,
    tenureStart: 2009,
    title: 'UI Developer',
  },
  {
    description: 'Web CMS development and design agency for clients including Seagate, UC Berkeley, and various SMBs.',
    highlight: false,
    logo: null,
    org: 'Quiddities',
    outcome: null,
    hasPortfolioPage: false,
    portfolioRoute: '/portfolio/quiddities',
    tenureEnd: 2008,
    tenureStart: 2008,
    title: 'UI/UX Designer',
  },
];
