import React from 'react';
import { GRIDSET_OVERLAY, theme } from 'lib/theme';
import { GridsetAllColumns } from 'pages/Styleguide/GridsetAllColumns';


export const GridsetOverlay = () => {
  if (! GRIDSET_OVERLAY) return false;
  return (
    <GridsetAllColumns
      alignItems="flex-start"
      bottom={0}
      color="black"
      fontFamily="monospace"
      fontSize={{ base: '2xs', sm: 'xs' }}
      fontWeight="bold"
      height="100%"
      left={0}
      opacity={0.1}
      paddingX={theme.space.viewportPaddingX}
      position="fixed"
      right={0}
      style={{ pointerEvents: 'none' }}
      textShadow={`
        white 0px 0px 2em,
        white 0px 0px 1.5em,
        white 0px 0px 1em,
        white 0px 0px 0.5em,
        white 0px 0px 0.25em,
        white 0px 0px 0.1em,
        white 1px 1px 1px,
        white 1px -1px 1px,
        white -1px -1px 1px,
        white -1px 1px 1px
      `}
      top={0}
      zIndex="max"
    />
  );
};
