import PropTypes from 'prop-types';
import React from 'react';
import { Decoration } from 'components/Decoration';
import { Flex } from 'components/Layout';
import {
  Title,
} from 'components/Typography';


export const SectionHeader = ({ color, colorDecoration, title, ...props }) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    {...props}
  >
    <Title color={color || 'app.text'}>{title}</Title>
    <Decoration color={colorDecoration || color || 'scheme.primary.base'} placement="header" triggerOnce={false} />
  </Flex>
);

SectionHeader.propTypes = {
  color: PropTypes.string,
  colorDecoration: PropTypes.string,
  title: PropTypes.string.isRequired,
};
