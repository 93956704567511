import { useCallback, useState, useEffect } from 'react';
import { theme } from 'lib/theme';


const breakpoints = theme.breakpoints.reverse().map(breakpoint => `(min-width: ${breakpoint})`);

export function useMediaQuery() {
  const values = ['xl', 'lg', 'md', 'sm', 'base']; // hardcoded, match labeled theme.breakpoints keys
  const defaultValue = 'base';

  // Array containing a MediaQueryList object for each query. Disabled if no window (eg server side render).
  const mediaQueryLists = typeof window !== 'undefined' ? breakpoints.map(breakpoint => window.matchMedia(breakpoint)) : [];

  // Get value for matched media query
  const getValue = useCallback(() => {
    const index = mediaQueryLists.findIndex(mql => mql.matches); // Get index of first media query that matches
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue; // Return related value or defaultValue if none
  }, [values, defaultValue, mediaQueryLists]);

  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      const handler = () => setValue(getValue);
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler)); // Remove listeners on cleanup
    }, [getValue, mediaQueryLists]
  );
  return value;
}

export const LAYOUT_SWITCH = 'md';

export function getBreakpoint(currentBreakpoint) {
  return {
    large: currentBreakpoint === 'md' || currentBreakpoint === 'lg' || currentBreakpoint === 'xl',
    small: currentBreakpoint === 'base' || currentBreakpoint === 'sm',
    switch: LAYOUT_SWITCH,
  };
};
