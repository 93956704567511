import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';


export const ArticleBody = ({ children, defaultColumns = true, ...props }) => {
  let childCols;
  if (!! defaultColumns) { childCols = (
    <Box
      children={children}
      gridColumn={ theme.sizes.article.gridColumns }
    />
  );} else {
    childCols = children;
  }

  return (
    <Gridset
      children={ childCols }
      className="typographyEnabled"
      paddingX={theme.space.viewportPaddingX}
      {...props}
    />
  );
};

ArticleBody.propTypes = {
  children: PropTypes.node.isRequired,
  defaultColumns: PropTypes.bool,
};
