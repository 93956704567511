import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Box } from 'components/Layout';


export const ScrollProgressBar = ({ element, ...props }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollListener = () => {
    const el = element.current;
    if (! el) return false;

    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const elementOffsetTop = el.offsetTop;
    const scrollableHeight = el.clientHeight - elementOffsetTop - (window.innerHeight);

    if (windowScrollTop - elementOffsetTop <= 0) return setScrollProgress(0);
    if (windowScrollTop >= scrollableHeight + elementOffsetTop) return setScrollProgress(100);
    setScrollProgress((windowScrollTop - elementOffsetTop) / scrollableHeight * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  });

  return (
    <Box width="100%" {...props}>
      <Box
        bg="scheme.secondary.base"
        height="100%"
        width={ (scrollProgress <= 100 ? scrollProgress : 100) + '%' }
      />
    </Box>
  );
};

ScrollProgressBar.propTypes = {
  element: PropTypes.object.isRequired,
};
