import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Spring } from 'react-spring/renderprops';
import {
  Icon,
} from '@chakra-ui/core';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { Card } from 'components/Card';
import { LineDivider } from 'components/Divider';
import { Box } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import {
  BodyAlt,
} from 'components/Typography';


export const CardExpandable = ({ children, colorHeaderNoTint, description, imageSVGViewBox, imageSize, imageSrc, isSVG, title, toggleLabel, ...props }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(! show);

  return (
    <Card
      alignItems="stretch"
      color="app.text"
      colorHeaderNoTint={colorHeaderNoTint}
      display="flex"
      flexDirection="column"
      icon={ !! imageSrc && {
        alt: title,
        size: imageSize,
        src: imageSrc,
        SVG: !! isSVG ? {
          fill: `${colorHeaderNoTint}.base`,
          isSVG: true,
          viewBox: imageSVGViewBox,
        } : {},
      }}
      justifyContent="flex-start"
      minHeight={{
        base: null,
        [LAYOUT_SWITCH]: '14rem',
        lg: '12rem',
      }}
      title={title}
      transform={{ [LAYOUT_SWITCH]: 'translate3d(0, -25%, 0)' }}
      transition="all 0.2s ease-out"
      zIndex="float"
      {...props}
    >
      <BodyAlt as="div" flexGrow={1}>{description}</BodyAlt>

      <Spring
        to={{
          display: !! show ? 'block' : 'none',
          height: !! show ? 'auto' : 0,
          opacity: !! show ? 1 : 0,
        }}
      >
        {springStyle => (
          <Box
            paddingBottom={2}
            style={{
              ...springStyle,
              overflow: 'hidden'
            }}
          >
            <LineDivider marginY={4} />
            {children}
          </Box>
        )}
      </Spring>

      <LinkInternal
        _active={{
          bg: `${colorHeaderNoTint}.light`,
          color: `${colorHeaderNoTint}.darker`,
        }}
        _hover={{
          bg: `${colorHeaderNoTint}.lighter`,
          color: `${colorHeaderNoTint}.dark`,
        }}
        alignItems="center"
        bg={`${colorHeaderNoTint}.base`}
        color="white"
        display="flex"
        fontFamily="base"
        fontSize="xs"
        fontWeight="semibold"
        justifyContent="stretch"
        marginTop={4}
        onClick={handleToggle}
        padding={2}
        textAlign="center"
        width="100%"
      >
        <Icon flexGrow={0} fontSize="lg" name={`chevron-${! show ? 'down' : 'up' }`} position="absolute" />
        <Box flexGrow={1}>
          {! show ? 'Show' : 'Hide' } {!! toggleLabel ? ! show && toggleLabel : 'More'}
        </Box>
      </LinkInternal>

    </Card>
  );
};

CardExpandable.propTypes = {
  children: PropTypes.node.isRequired,
  colorHeaderNoTint: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  imageSVGViewBox: PropTypes.string,
  imageSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  imageSrc: PropTypes.string.isRequired,
  isSVG: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggleLabel: PropTypes.string,
};
