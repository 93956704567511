import PropTypes from 'prop-types';
import React from 'react';
import { Gridset } from 'components/Gridset';
import { Flex } from 'components/Layout';


export const GridsetColumn = ({ alignItems, bg, fontSize, gridArea, gridColumn, label }) => (
  <Flex
    alignItems={ alignItems || 'center' }
    bg={bg || 'scheme.secondary.light'}
    borderColor="dangerPalette.white"
    borderWidth={1}
    children={label || gridColumn || gridArea}
    fontSize={ fontSize || '2xs' }
    gridArea={gridArea}
    gridColumn={gridColumn}
    justifyContent="center"
    minHeight={8}
    paddingY={2}
    textAlign="center"
  />
);

GridsetColumn.propTypes = {
  alignItems: PropTypes.string,
  bg: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object, // for responsive sizes
  ]),
  gridArea: PropTypes.string,
  gridColumn: PropTypes.string,
  label: PropTypes.string,
};

export const GridsetAllColumns = ({ alignItems, fontSize, ...props }) => (
  <Gridset {...props}>
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a0b0 / a05" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a05 / a1" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a1 / b1" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="b1 / a2" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a2 / a25" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a25 / a3b2" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a3b2 / a35" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a35 / a4" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a4 / b3" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="b3 / a5" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a5 / a55" />
    <GridsetColumn alignItems={alignItems} bg="scheme.accent.dark" fontSize={fontSize} gridColumn="a55 / a6b4" />
  </Gridset>
);

GridsetAllColumns.propTypes = {
  alignItems: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object, // for responsive sizes
  ]),
};
