import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'lib/helmet';
import { getJob } from 'lib/helpers';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { ArticleHeader } from 'components/ArticleHeader';
import { SectionDivider } from 'components/Divider';
import { Box } from 'components/Layout';

import { spotifyInternalRoutes } from './spotifyInternalRoutes';
import { Audience } from './Audience';
import { AudienceQuotes } from './Audience/AudienceQuotes';
// import { Cadence } from './Cadence';
import { Mission } from './Mission';
import { Intro } from './Intro';
import { Project } from './Project';
import { SpotifyNav } from './SpotifyNav';
// import { Team } from './Team';


const job = getJob('Spotify');

export const Spotify = () => {
  const articleRef = useRef(null);
  return (<>
    <Helmet>
      <title>{pageTitle(job.org)}</title>
    </Helmet>
    <Box
      as="article"
      paddingTop={{
        base: 12, // room for sticky scroll nav in small viewports
        [LAYOUT_SWITCH]: 0,
      }}
      ref={articleRef}
    >
      <ArticleHeader
        titlePrimary={job.org}
        titleSecondary={job.title}
        titleTertiary={
          `${(!! job.tenureStart && job.tenureStart !== job.tenureEnd) ? job.tenureStart + ' – ' : ''}${job.tenureEnd}`
        }
      />
      <Intro />
      <SectionDivider height="half" />
      <Box
        height={{
          base: 8,
          [LAYOUT_SWITCH]: 0,
        }}
        id={spotifyInternalRoutes.mission}
      />
      <SpotifyNav
        scollProgressElement={articleRef}
        spotifyInternalRoutes={spotifyInternalRoutes}
      />
      <Box
        bg="dangerPalette.jet.base"
        paddingTop={{
          base: 24, // room for sticky scroll nav in small viewports
          [LAYOUT_SWITCH]: 16,
        }}
      >
        <Mission />
        <Box bg="dangerPalette.jet.dark">
          <Audience />
          <AudienceQuotes />
        </Box>
      </Box>
      <Project spotifyInternalRoutes={spotifyInternalRoutes} />
    </Box>
  </>);
};
