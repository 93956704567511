import PropTypes from 'prop-types';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Image } from '@chakra-ui/core';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { CloseButton } from 'components/CloseButton';
import { Decoration } from 'components/Decoration';
import {
  Box,
  Flex,
} from 'components/Layout';
import {
  Header,
  BodyAlt,
} from 'components/Typography';
import styles from './styles.module.css';


export const CardContentHeader = ({ ...props }) => (<Header {...props} />);

export const Card = ({ children, closeFunc, color, colorHeaderNoTint, hasDecoration = true, hasIconBg = true, icon, subtitle, title, ...props }) => (
  <Box
    as="section"
    bg="white"
    borderRadius="sm"
    boxShadow={2}
    color={ color || 'app.text' }
    fontSize="sm"
    paddingX={4}
    paddingY={4}
    position={ closeFunc && 'relative' }
    textAlign="start"
    {...props}
  >
    { !! closeFunc && <CloseButton onClick={closeFunc} /> }
    <Flex
      alignItems={ (!! icon && !! icon.src) ? 'center' : 'flex-start'}
      flexDirection={{
        base: 'column',
        [LAYOUT_SWITCH]: !! icon && 'row'
      }}
      justifyContent="flex-start"
      paddingBottom={{
        base: 0,
        [LAYOUT_SWITCH]: (!! icon && !! icon.src) && 2
      }}
    >

      { !! icon && !! icon.src &&
        <Box
          as="figure"
          bg={!! hasIconBg && `${colorHeaderNoTint}.base`}
          borderRadius={!! hasIconBg && '2xl' }
          color={!! icon.SVG.fill ? icon.SVG.fill : icon.SVG.stroke ? icon.SVG.stroke : 'transparent' }
          flexGrow={0}
          flexShrink={0}
          marginRight={{
            base: 0,
            [LAYOUT_SWITCH]: 4,
          }}
          overflow="hidden"
          padding={ 1 }
        >
          { !! icon.SVG.isSVG &&
            <SVG
              className={ styles.SVG }
              height={icon.size}
              preserveAspectRatio="xMidYMid slice"
              src={icon.src}
              stroke={icon.SVG.stroke}
              strokeWidth={icon.SVG.strokeWidth}
              viewBox={icon.SVG.viewBox}
              width={icon.size}
            />
          }
          { ! icon.SVG.isSVG &&
            <Image
              height={icon.size}
              src={icon.src}
              width={icon.size}
            />
          }
        </Box>
      }

      <Box>
        {!! title &&
          <Header
            color={ !! colorHeaderNoTint ? `${colorHeaderNoTint}.dark` : 'scheme.secondary.dark'}
            textTransform="uppercase"
          >
            {title}
          </Header>
        }
        { !! subtitle &&
          <BodyAlt
            borderBottomColor="app.divider"
            borderBottomWidth="1px"
            children={subtitle}
            color={ !! colorHeaderNoTint ? `${colorHeaderNoTint}.base` : 'scheme.secondary.base' }
            marginBottom={3}
            paddingBottom={3}
          />
        }
        { !! hasDecoration &&
          <Decoration
            color={ !! colorHeaderNoTint ? `${colorHeaderNoTint}.light` : 'scheme.secondary.light'}
            marginTop={2}
            placement="content"
            triggerOnce={false}
          />
        }
      </Box>
    </Flex>
    {children}
  </Box>
);

Card.propTypes = {
  children: PropTypes.any,
  closeFunc: PropTypes.func,
  color: PropTypes.any,
  colorHeaderNoTint: PropTypes.string,
  hasDecoration: PropTypes.bool,
  hasIconBg: PropTypes.bool,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    src: PropTypes.string,
    SVG: PropTypes.shape({
      fill: PropTypes.string,
      isSVG: PropTypes.bool,
      stroke: PropTypes.string,
      strokeWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      viewBox: PropTypes.string,
    }),
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
