import React from 'react';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import {
  Text,
  Headline1,
  Headline1Alt,
  Headline2,
  Headline2Alt,
  Headline3,
  Headline3Alt,
  Title,
  TitleAlt,
  Subtitle,
  SubtitleDecorated,
  Header,
  HeaderDecorated,
  Body,
  BodyAlt,
  Label,
  Small,
} from 'components/Typography';

const ListItem = () => (<li>This is a list item.</li>);

const Lists = () => (
  <Box>
    <ul>
      <ListItem />
      <ListItem />
      <ul>
        <ListItem />
        <ListItem />
        <ul>
          <ListItem />
          <ListItem />
          <ul>
            <ListItem />
          </ul>
        </ul>
      </ul>
    </ul>
    <ol>
      <ListItem />
      <ListItem />
      <ol>
        <ListItem />
        <ListItem />
        <ol>
          <ListItem />
          <ListItem />
          <ol>
            <ListItem />
          </ol>
        </ol>
      </ol>
    </ol>
  </Box>
);

const Table = () => {
  const rows = [];
  for (let i=0; i<5; i++) {
    rows.push(
      <tr key={i}>
        <th>Row</th>
        <td>Cell</td>
        <td>Cell</td>
        <td>Cell</td>
        <td>Cell</td>
      </tr>
    );
  }
  return (
    <table>
      <thead>
        <tr>
          <th>Column</th>
          <th>Column</th>
          <th>Column</th>
          <th>Column</th>
          <th>Column</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};

const Verse = () => (
  <Box>
    <Text as="h1" my={2}>This is an H1.</Text>
    <Text as="h2" my={2}>This is an H2.</Text>
    <Text as="h3" my={2}>This is an H3.</Text>
    <Text as="h4" my={2}>This is an H4.</Text>
    <Text as="h5" my={2}>This is an H5.</Text>
    <Text as="h6" my={2}>This is an H6.</Text>
    <Text as="p" my={2}>This is a p.</Text>
  </Box>
);

export const Typography = () => (
  <Gridset
    alignItems="start"
    paddingX={theme.space.viewportPaddingX}
    paddingY="gutterOutside"
  >
    <Box
      as="section"
      className="typographyEnabled"
      gridColumn={{
        base: 'span 12',
        md: 'span 6',
        lg: 'a05 / a3b2'
      }}
      gridRow={1}
      paddingX={{ base: 0, md: 4 }}
      paddingY={4}
    >
      <Verse />
      <Lists />
      <Table />
    </Box>
    <Box
      as="section"
      gridColumn={{
        base: 'span 12',
        md: 'span 6',
        lg: 'a3b2 / a55'
      }}
      gridRow={{ base: 2, md: 1 }}
      paddingX={{ base: 0, md: 4 }}
      paddingY={4}
    >
      <Headline1 as="h1" marginY={4}>This is a Headline1 component.</Headline1>
      <Headline1Alt as="h1" marginY={4}>This is a Headline1Alt component.</Headline1Alt>
      <Headline2 as="h2" marginY={4}>This is a Headline2 component.</Headline2>
      <Headline2Alt as="h2" marginY={4}>This is a Headline2Alt component.</Headline2Alt>
      <Headline3 as="h3" marginY={4}>This is a Headline3 component.</Headline3>
      <Headline3Alt as="h3" marginY={4}>This is a Headline3Alt component.</Headline3Alt>
      <Title as="h4" marginY={4}>This is a Title component.</Title>
      <TitleAlt as="h4" marginY={4}>This is a TitleAlt component.</TitleAlt>
      <Subtitle as="h4" marginY={4}>This is a Subtitle component.</Subtitle>
      <SubtitleDecorated as="h4" marginY={4}>This is a Subtitle component.</SubtitleDecorated>
      <Header as="h5" marginY={4}>This is a Header component.</Header>
      <HeaderDecorated as="h5" marginY={4}>This is a Header component.</HeaderDecorated>
      <Body as="p" marginY={4}>This is a Body component.</Body>
      <BodyAlt as="p" marginY={4}>This is a BodyAlt component.</BodyAlt>
      <Label as="p" marginY={4}>This is a Label component.</Label>
      <Small as="p" marginY={4}>This is a Small component.</Small>
    </Box>
  </Gridset>
);
