import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet-async";
import { pageTitle } from "lib/helmet";
import { theme } from "lib/theme";
import { Box, Flex } from "components/Layout";
import { LinkInternal } from "components/LinkInternal";
import { Gridset } from "components/Gridset";
import { Hero } from "./Hero";
import { Login } from "./Login";

export const Home = ({ loggedIn }) => (
  <>
    <Helmet>
      <title>{pageTitle("Product Designer")}</title>
    </Helmet>
    <Flex
      alignItems="stretch"
      color="app.text"
      flexDirection="column"
      justifyContent="stretch"
      maxWidth="100vw"
      minHeight="aboveFold"
    >
      <Hero />
      <Box
        flexGrow={1} // putting flexGrow directly on Gridset causes Safari reflow issues
      >
        <Gridset
          alignItems="start"
          gridTemplateRows="100%"
          justifyContent="stretch"
          maxWidth="100vw"
          paddingBottom="gutterInside"
          paddingX={theme.space.viewportPaddingX}
        >
          <Box
            gridColumn={{
              base: "span 12",
              md: "a05 / a55",
              lg: "a05 / a4",
              xl: "a05 / a35",
            }}
            paddingX={{
              base: 0,
              sm: 0.5 * theme.sizes.gridset.col6 + "%",
              md: 0,
            }}
          >
            <Login loggedIn={loggedIn} />
            <LinkInternal
              _active={{
                bg: "white",
                color: "linkExternal.colorActive",
              }}
              _hover={{
                bg: "white",
                color: "linkExternal.colorHover",
              }}
              alignItems="center"
              bg="white"
              borderRadius="md"
              boxShadow={2}
              color="linkExternal.color"
              display="inline-flex"
              fontFamily="header"
              fontSize="sm"
              fontWeight="bold"
              href="https://www.linkedin.com/in/harlanlewis/"
              justifyContent="flex-start"
              ml="2"
              paddingX={{ base: 6, md: 4 }}
              paddingY={4}
              textAlign="center"
            >
              LinkedIn
            </LinkInternal>
          </Box>
        </Gridset>
      </Box>
    </Flex>
  </>
);

Home.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};
