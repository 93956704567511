import PropTypes from 'prop-types';
import React from 'react';
import {
  Field,
  Formik,
} from 'formik';
import { Spring } from 'react-spring/renderprops';
import {
  FormControl,
  FormErrorMessage,
  Input,
  Textarea,
} from '@chakra-ui/core';
import {
  LAYOUT_SWITCH,
  getBreakpoint,
  useMediaQuery,
} from 'lib/mediaQuery';
import { Card } from 'components/Card';
import { Box } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import stylesScroll from 'style/scroll.module.css';


const labels = {
  email: 'Email address',
  name: 'Your name',
  message: 'How can I help',
};
const validateEmail = (value) => { if (! value) return <Box as="span" fontSize="xs" lineHeight="1">{labels.email}:</Box>; };
const validateMessage = (value) => { if (! value) return <Box as="span" fontSize="xs" lineHeight="1">{labels.message}?</Box>; };
const validateName = (value) => { if (! value) return <Box as="span" fontSize="xs" lineHeight="1">{labels.name}:</Box>; };

const propsField = {
  borderColor: 'app.divider',
  fontSize: 'xs',
  padding: 2,
};


export const AppNavContactPopup = ({ handleToggle, show }) => {
  const currentBreakpoint = useMediaQuery();
  const breakpoint = getBreakpoint(currentBreakpoint);

  return (
    <Spring
      to={{
        display: !! show ? 'block' : 'none',
        opacity: !! show ? 1 : 0,
        transform: `translate3d(0, ${!! show ? 0 : !! breakpoint.small ? '10vh' : '-1rem'}, 0)`,
      }}
    >
      {springStyle => (
        <Box
          bottom={{
            base: 0,
            [LAYOUT_SWITCH]: 'initial',
          }}
          left={{
            base: 0,
            [LAYOUT_SWITCH]: 'initial',
          }}
          position={{
            base: 'fixed',
            [LAYOUT_SWITCH]: 'absolute'
          }}
          right={{
            base: 0,
            [LAYOUT_SWITCH]: 0,
          }}
          style={springStyle}
          top={{
            base: 'initial',
            [LAYOUT_SWITCH]: 'calc(100% + 1rem)',
          }}
          zIndex="overlay"
        >
          <Card
            bg="white"
            borderBottomLeftRadius={{
              base: 0,
              [LAYOUT_SWITCH]: 'lg',
            }}
            borderBottomRightRadius={{
              base: 0,
              [LAYOUT_SWITCH]: 'lg',
            }}
            borderRadius={null} // override defaults to avoid collisions
            borderTopLeftRadius={{
              base: 'lg',
              [LAYOUT_SWITCH]: 'lg',
            }}
            borderTopRightRadius={{
              base: 'lg',
              [LAYOUT_SWITCH]: 'lg',
            }}
            boxShadow={{
              base: 5,
              [LAYOUT_SWITCH]: 3,
            }}
            className={stylesScroll.scrollbar}
            closeFunc={handleToggle}
            color="app.textHighlight"
            maxHeight={{
              base: 'heightBelowNav',
              [LAYOUT_SWITCH]: '80vh',
            }}
            maxWidth={{
              base: '100%',
              [LAYOUT_SWITCH]: '80vw',
            }}
            overflow="auto"
            title="Let’s Talk"
            width={{
              [LAYOUT_SWITCH]: '20rem',
            }}
          >
            <Formik
              initialValues={{
                email: '',
                message: '',
                name: '',
              }}
            >
              {({ isSubmitting }) => (
                <form method="post" name="contact">
                  <input name="form-name" type="hidden" value="contact" /> {/* hooked up to Netlify HTML form in index.html*/}
                  <Box>
                    <Field name="name" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.name && form.touched.name} isRequired>
                          <FormErrorMessage fontSize="xs">
                            {form.errors.name}
                          </FormErrorMessage>
                          <Box paddingTop={2}>
                            <Input {...field} {...propsField} id="name" placeholder={labels.name} />
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Field name="email" validate={validateEmail}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.email && form.touched.email} isRequired>
                          <FormErrorMessage fontSize="xs">
                            {form.errors.email}
                          </FormErrorMessage>
                          <Box paddingTop={2}>
                            <Input {...field} {...propsField} id="email" placeholder={labels.email} />
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <Box paddingY={4}>
                    <Field name="message" validate={validateMessage}>
                      {({ field, form }) => (
                        <FormControl isInvalid={form.errors.message && form.touched.message} isRequired>
                          <FormErrorMessage fontSize="xs">
                            {form.errors.message}
                          </FormErrorMessage>
                          <Box paddingTop={2}>
                            <Textarea {...field} {...propsField} id="message" minHeight="6rem" placeholder={labels.message + '?'} />
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                  <LinkInternal
                    bg={! isSubmitting ? 'scheme.secondary.dark' : 'scheme.secondary.light'}
                    children={! isSubmitting ? 'Send Message' : 'Sending...'}
                    color="white"
                    isLoading={isSubmitting}
                    padding={ 2 }
                    type="submit"
                  />
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      )}
    </Spring>
  );
};

AppNavContactPopup.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
