import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'components/Layout';


export const LineDivider = ({ ...props }) => (
  <Box
    bg="app.divider"
    height="1px"
    {...props}
  />
);


export const SectionDivider = ({ id, height, ...props }) => {
  const heightDefault = 24;
  return (
    <Box
      height={ height === 'half' ? 0.5 * heightDefault : (height || heightDefault) }
      id={id}
      style={{ clear: 'both' }}
      {...props}
    />
  );
};

SectionDivider.propTypes = {
  header: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  id: PropTypes.string,
};
