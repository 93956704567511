import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@chakra-ui/core';
import { handleLogIn } from 'lib/auth';
import { routes } from 'lib/router/routes';
import { LinkInternal } from 'components/LinkInternal';


export const Login = ({ loggedIn }) => (
  <LinkInternal
    _active={{
      bg: 'scheme.primary.base',
      color: 'white',
    }}
    _hover={{
      bg: 'scheme.primary.dark',
      color: 'white',
    }}
    alignItems="center"
    bg="scheme.primary.base"
    borderRadius="md"
    boxShadow={2}
    color="whiteAlpha.900"
    display="inline-flex"
    fontFamily="header"
    fontSize="sm"
    fontWeight="bold"
    justifyContent="flex-start"
    onClick={! loggedIn ? handleLogIn : null}
    paddingX={{ base: 6, md: 4 }}
    paddingY={4}
    textAlign="center"
    to={!! loggedIn ? routes.StrongDM : null}
  >
    { !! loggedIn ? 'View work' : 'Log in to view work'}
    <Icon
      marginLeft={2}
      name="arrow-right"
      size="1em"
    />
  </LinkInternal>
);


Login.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
};
