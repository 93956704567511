import PropTypes from 'prop-types';
import React from 'react';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import {
  Subtitle,
  BodyAlt,
} from 'components/Typography';

import { LayerContent } from './LayerContent';


export const LayerRow = ({ description, level, title }) => (
  <Gridset
    alignItems="center"
    justifyContent="center"
    paddingX={theme.space.viewportPaddingX}
    paddingY={6}
  >
    <Box
      color="card.text"
      gridColumn={{
        base: 'a0b0 / a2',
        lg: 'a05 / a2',
      }}
      gridRow={1}
      position="relative"
      zIndex="float"
    >
      <Subtitle children={title} color="scheme.tertiary.base" lineHeight="base" />
      <BodyAlt as="p" children={description} fontSize="sm" />
    </Box>
    <LayerContent level={level} />
  </Gridset>
);

LayerRow.propTypes = {
  description: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
