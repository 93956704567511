import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { ArticleBody } from 'components/ArticleBody';
import { ArticleImage } from 'components/ArticleImage';
import { Aside } from 'components/Aside';
import { SectionDivider } from 'components/Divider';
import { LinkExternal } from 'components/LinkExternal';
import { LinkInternal } from 'components/LinkInternal';
import { SectionHeader } from 'components/SectionHeader';
import {
  Subtitle,
  Header,
} from 'components/Typography';


const assetPath = '/assets/portfolio/spotify/';

export const ProjectExploration = ({ spotifyInternalRoutes }) => (<>
  <ArticleBody>
    <SectionDivider id={spotifyInternalRoutes.exploration} />
    <SectionHeader alignItems="flex-start" colorDecoration="scheme.secondary.dark" title="Exploration & Feedback" />
    <p>Tools vary and change over time, but I always try to match time spent on the details (and design fidelity in general) to the audience and goal:</p>
    <ul>
      <li>Generate a wide variety of ideas to explore alternate approaches.</li>
      <li>Validate value of ideas from customers.</li>
      <li>Communicate design intent & requirements to the immediate team.</li>
      <li>Keep stakeholders informed.</li>
    </ul>

    <Aside isPadded placement="right">
      <ArticleImage
        alt="Crazy 8s sketches generated by the team."
        modalSrc={`${assetPath}crazy8s.png`}
        src={`${assetPath}crazy8s-thumb.png`}
      />
    </Aside>
    <Subtitle>Facilitated Collaboration</Subtitle>
    <p>As a distributed team, we developed remote-first processes to keep everyone engaged. I regularly facilitated design exercises for product, engineering, and data:</p>
    <ol>
      <li>Affinity diagramming to quickly reach consensus on subjective priorities. For example, when defining principles for a major new effort, we might work through three focus questions in sequence:
        <ol>
          <li><strong>For our customers to value this</strong>, we should/should not…</li>
          <li><strong>For Spotify to value this</strong>…</li>
          <li><strong>For the project to be successful</strong>…</li>
        </ol>
      </li>
      <li>Hypothesis-driven ideation, contextualizing solutions with high level goals and research.</li>
      <li>Drawing together and paired prototype spikes.</li>
    </ol>

    <SectionDivider height="half" id={spotifyInternalRoutes.feedback} />
    <Subtitle>Feedback Loops</Subtitle>
    <p>I ran feedback sessions each sprint with users matching our <LinkInternal href={`#${spotifyInternalRoutes.audience}`}>personas</LinkInternal>, in addition to weekly design critiques with horizontal designers.</p>
    <Aside
      isPadded
      marginBottom={{ [LAYOUT_SWITCH]: '1rem' }}
      placement="right"
      transform={{ [LAYOUT_SWITCH]: 'translate3d(0, 1rem, 0)' }}
    >
      <ArticleImage
        alt="Feedback intake and review in Google Sheets."
        modalSrc={`${assetPath}googleSheets-feedback.png`}
        src={`${assetPath}googleSheets-feedback-thumb.png`}
      />
    </Aside>
    <Aside isPadded placement="right">
      <ArticleImage
        alt="Google Gallery of iterative compositions, shared with users for feedback."
        modalSrc={`${assetPath}googleGallery-projects.png`}
        src={`${assetPath}googleGallery-projects-thumb.png`}
      />
    </Aside>
    <p>When collecting feedback, three things are important:</p>
    <ol>
      <li>Create an open, empathetic setting that encourages first-take thinking.</li>
      <li>Keep your attention on the person and the task - use tooling to capture notes.</li>
      <li>Record if the participant has a clear <q>why</q> for issues - but don't ask for it, these are rarely trustworthy. Ask what they would rather have instead.</li>
    </ol>
    <p>After a session, notes were categorized by severity, type, and cause.</p>

    <SectionDivider height="half" />

    <Subtitle>Design & Interface Systems</Subtitle>
    <p>Our interface components were heavily informed by UI patterns for internal web tools. and connected the <em>Insights & Experiments</em> frontend engineers with other teams building similar component libraries.</p>
    <Aside isPadded placement="left">
      <ArticleImage
        alt="Design patterns for web dialog boxes."
        modalSrc={`${assetPath}tape-dialog.png`}
        src={`${assetPath}tape-dialog-thumb.png`}
      />
    </Aside>
    <p>Spotify’s design patterns and tokens for internal web tools was less mature than Spotify’s rich consumer principles, guidelines, and patterns. An internal system was necessary for applications more oriented to data, transactions, and tasks than browsing and playback.</p>
    <p>In some areas, I tied our design to internal standards to fit comfortably within the Spotify ecosystem. In others, I adhered more closely to related tools, helping standards emerge for our product area.</p>
    <p>For components specific to our UI, we used <LinkExternal href="https://storybook.js.org/">Storybook</LinkExternal> and an integrated styleguide to document usage, appearance, and interaction states.</p>

    <Aside isPadded placement="left" style={{ clear: 'both' }}>
      <ArticleImage
        alt="Accessible color scale for visually indicating the estimated effect of a behavior."
        modalSrc={`${assetPath}beanstalk-estimatedEffectAccessibility.png`}
        src={`${assetPath}beanstalk-estimatedEffectAccessibility-thumb.png`}
      />
    </Aside>
    <Header>Accessibility</Header>
    <p>Accessibility and usability are two spectrums for understanding a shared goal - ensuring that humans can access, use, enjoy, and benefit from services and content. Investing in accessibility empowers both humans and devices across a range of capabilities and contexts. It requires coordinated effort across both technical implementation and design.</p>
    <p>When designing custom components, I used tools like <LinkExternal href="https://cluse.cc">Cluse</LinkExternal> and <LinkExternal href="https://khan.github.io/tota11y/">tota11y</LinkExternal> to evaluate and guide accessibility efforts in Sketch and on the web, where I advocated the use of semantic markup, aria roles, and keyboard-accessible interactive elements.</p>
    <p>The pictured example shows our <em>positive ↔ neutral ↔ negative</em> color scale for our Estimated Effect metric. This type of scale is typically <em>green ↔ red</em>, lacking contrast across lightness levels and the cause of perception issues for users. I designed a <em>blue ↔ red</em> scale that stepped through lumosity in addition to hue. This had the secondary benefit of emphasizing values as they diverged from the baseline.</p>

  </ArticleBody>
</>);

ProjectExploration.propTypes = {
  spotifyInternalRoutes: PropTypes.object.isRequired,
};
