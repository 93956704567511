import PropTypes from 'prop-types';
import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { theme } from 'lib/theme';
import { Decoration } from 'components/Decoration';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import {
  Headline1,
  TitleAlt,
  Header,
} from 'components/Typography';


export const ArticleHeader = ({ color, pageHeader, titlePrimary, titleSecondary, titleTertiary, ...props }) => (
  <Box
    paddingTop={{
      base: 16,
      [LAYOUT_SWITCH]: 'gutterOutside'
    }}
    paddingX={theme.space.viewportPaddingX}
  >
    <Spring
      from={{
        opacity: 0,
        visibility: 'hidden',
      }}
      to={{
        opacity: 1,
        visibility: 'visible',
      }}
    >
      {springStyle => (
        <Gridset
          as="header"
          style={springStyle}
          {...props}
        >
          <Box gridColumn={theme.sizes.article.gridColumns}>
            { !! pageHeader &&
                <Header
                  children={pageHeader}
                  color={ !! color ? color : 'scheme.primary.base' }
                  textTransform="uppercase"
                />
            }
            <Headline1
              as="h1"
              children={titlePrimary}
              paddingBottom={4}
              paddingTop={2}
            />
            { !! titleSecondary &&
                  <TitleAlt as="h2">
                    {titleSecondary}
                    { !! titleTertiary &&
                      <Box children={titleTertiary} paddingTop={2}
                      />
                    }
                  </TitleAlt>
            }
            <Decoration color={color} placement="header" triggerOnce={false} />
          </Box>
        </Gridset>
      )}
    </Spring>
  </Box>
);

ArticleHeader.propTypes = {
  color: PropTypes.string,
  pageHeader: PropTypes.string,
  titlePrimary: PropTypes.string.isRequired,
  titleSecondary: PropTypes.string,
  titleTertiary: PropTypes.string,
};
