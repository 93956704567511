import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'components/Layout';


const displayValues = ['block', 'inline', 'flex', 'grid', 'inline-flex', 'none'];

const defaults = {
  base: 'none',
  sm: 'none',
  md: 'block',
};

export const ResponsiveHide = ({ base, sm, md, lg, xl, ...props }) => (
  <Box
    as="span"
    display={{
      base: base || defaults.base,
      sm: sm || base || defaults.sm,
      md: md || defaults.md,
      lg: lg || md || defaults.md,
      xl: xl || lg || md || defaults.md,
    }}
    {...props}
  />
);

ResponsiveHide.propTypes = {
  base: PropTypes.oneOf(displayValues),
  lg: PropTypes.oneOf(displayValues),
  md: PropTypes.oneOf(displayValues),
  sm: PropTypes.oneOf(displayValues),
  xl: PropTypes.oneOf(displayValues),
};
