import React from 'react';
import { Helmet } from 'react-helmet-async';
import { pageTitle } from 'lib/helmet';
import { GridsetLayout } from './GridsetLayout';
import { GridsetSpec } from './GridsetSpec';
import { Palette } from './Palette';
import { Shadows } from './Shadows';
import { Typography } from './Typography';


export const Styleguide = () => (<>
  <Helmet>
    <title>{pageTitle('Styleguide')}</title>
  </Helmet>
  <GridsetSpec />
  <GridsetLayout />
  <Typography />
  <Shadows />
  <Palette />
</>);
