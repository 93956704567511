import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { routes } from 'lib/router/routes';
import { theme } from 'lib/theme';
import { Aside } from 'components/Aside';
import { CardContentHeader } from 'components/Card';
import { CardExpandable } from 'components/CardExpandable';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { Box } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import { SectionHeader } from 'components/SectionHeader';


const Product = ({ imageSrc, size = '3em', ...props }) => (
  <Box
    gridColumn={{
      base: 'span 12',
      [LAYOUT_SWITCH]: 'span 6',
    }}
    minHeight={56}
    position="relative"
  >
    <CardExpandable
      hasIconBg={false}
      imageSize={size}
      imageSrc={`/assets/portfolio/preact/${imageSrc}`}
      marginX={{ base: 0, [LAYOUT_SWITCH]: 4 }}
      marginY={{ base: 4, [LAYOUT_SWITCH]: 0 }}
      position={{ [LAYOUT_SWITCH]: 'absolute' }}
      zIndex={ theme.zIndices.float + 1 } // float above surrounding content with zIndex, eg ArticleImages
      {...props}
    />
  </Box>
);

Product.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export const Products = () => (
  <Aside placement="wide">
    <SectionDivider height="half" />
    <SectionHeader color="dangerPalette.turquoise.base" title="The Products" />
    <SectionDivider height="half" />
    <Gridset
      alignItems="stretch"
      bg={{ [LAYOUT_SWITCH]: 'dangerPalette.flax.base' }}
      marginBottom={{ base: 0, [LAYOUT_SWITCH]: 32 }}
      marginTop={{ base: 0, [LAYOUT_SWITCH]: 16 }}
    >

      <Product
        colorHeaderNoTint="dangerPalette.turquoise"
        description={<div>
          <p>Preact was a customer success platform to maximize customer lifetime value for subscription software companies.</p>
          <p>It provided individualized activity-based health reports for millions of accounts to Customer Success and Support teams.</p>
        </div>}
        imageSrc="preactLogo.png"
        title="Preact"
        toggleLabel="Product Details"
      >
        <CardContentHeader color="dangerPalette.turquoise.base">Product Details</CardContentHeader>
        <p>Preact reduced churn and increased upsell by modeling and contextualizing detailed session-level usage across web, mobile, and server APIs.</p>
        <p>Preact offered significantly faster time-to-value and more flexible evaluation than competing rule-based customer success systems. Account-specific usage reports provided more timely and actionable insight into account health than lagging indirect metrics such as Net Promoter Score (NPS).</p>
        <p>Preact was used for Customer Success by companies like New Relic, RJMetrics, Kapost and Treasure Data.</p>
      </Product>

      <Product
        colorHeaderNoTint="dangerPalette.turquoise"
        description={<div>
          <p>Ptero used machine learning to discover predictive user behaviors on any mobile or web application.</p>
          <p>Ptero complemented Preact by identifying and clearly describing the specific user paths that correlated with growth metrics.</p>
        </div>}
        imageSrc="pteroLogoSymbol.svg"
        title="Ptero"
        toggleLabel="Product Details"
      >
        <CardContentHeader color="dangerPalette.turquoise.base">Product Details</CardContentHeader>
        <p>Ptero analyzed time-series data including clickstream events, transactions, and marketing activies to provide simple, shareable statements about user behaviors that led to feature usage, engagement, and subscription changes.</p>
        <p>After Spotify’s acquisition of Preact, Ptero became the foundation for the Insights & Experiments growth tools detailed in the <LinkInternal to={routes.Spotify}>Spotify Case Study</LinkInternal>.</p>
      </Product>

    </Gridset>
  </Aside>
);
