import React, { useState } from 'react';
import { Spring } from 'react-spring/renderprops';
import {
  Icon,
} from '@chakra-ui/core';
import { theme } from 'lib/theme';
import { ArticleBody } from 'components/ArticleBody';
import { SectionDivider } from 'components/Divider';
import { Gridset } from 'components/Gridset';
import { ImageRow } from 'components/ImageRow';
import {
  Box,
  Flex,
} from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import {
  Nbsp,
} from 'components/Typography';


const DataDisclaimer = () => {
  const [show, setShow] = useState(true);
  const handleToggle = () => setShow(! show);

  return (
    <Spring
      to={{
        height: !! show ? 'auto' : 0,
        opacity: !! show ? 1 : 0,
      }}
    >
      {springStyle => (
        <Flex
          alignItems="center"
          as="aside"
          bg="blackAlpha.300"
          borderRadius="md"
          color="whiteAlpha.900"
          fontSize="sm"
          fontWeight="medium"
          gridColumn={ theme.sizes.article.gridColumns }
          justifyContent="stretch"
          marginTop={8}
          marginX={1}
          overflow="hidden"
          paddingX={4}
          paddingY={2}
          style={springStyle}
        >
          <Box as="p" flexGrow={1} lineHeight="base">
            Just mock data, nothing to tell your friends about.
          </Box>
          <LinkInternal
            _active={{
              bg: 'green.50',
              color: 'green.700',
            }}
            _hover={{ bg: 'green.500' }}
            bg="whiteAlpha.200"
            color="white"
            flexGrow={0}
            justifySelf="flex-end"
            marginLeft={8}
            onClick={handleToggle}
            padding={2}
          >
            <Icon flexGrow={0} fontSize="1em" marginRight={2} name="check" />Got it
          </LinkInternal>
        </Flex>
      )}
    </Spring>
  );
};


export const ProjectIntro = () => (<>
  <SectionDivider />
  <ArticleBody>
    <Box color="app.bg.base" fontSize="xl" fontWeight="medium" paddingBottom={8} style={{ textWrap: 'balance' }} textAlign="center">
      <p>Redesign minimalist behavioral analysis tool as a self-serve hypothesis<Nbsp/>generator for product<Nbsp/>teams to influence growth<Nbsp/>outcomes.</p>
    </Box>
  </ArticleBody>

  <ImageRow images={[
    {
      alt: 'Beanstalk ML UI',
      modalSrc: '/assets/portfolio/spotify/beanstalk-previousIteration-scenario.png',
      src: '/assets/portfolio/spotify/beanstalk-previousIteration-scenario.png',
    },
    {
      alt: 'Beanstalk',
      modalSrc: '/assets/portfolio/spotify/beanstalk-scenario.png',
      src: '/assets/portfolio/spotify/beanstalk-scenario.png',
    },
  ]}
  springRevealTriggerOnce={true}
  />
  <Gridset>
    <DataDisclaimer />
  </Gridset>
  <SectionDivider height="half" />
</>);
