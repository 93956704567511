import PropTypes from 'prop-types';
import React from 'react';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { routes } from 'lib/router/routes';
import { Flex } from 'components/Layout';
import { LinkInternal } from 'components/LinkInternal';
import { ResponsiveHide } from 'components/ResponsiveHide';
import { Nbsp } from 'components/Typography';


export const AppNavBrand = ({ atHomePage, navStyles }) => {
  const responsiveText = {
    base: !! atHomePage ? 'inline' : 'none',
    sm: 'inline',
    md: 'inline',
  };
  return (
    <Flex
      alignItems="center"
      as="h1"
      flexGrow={0}
      flexShrink={0}
      gridColumn={{ [LAYOUT_SWITCH]: 'a0b0 / a1' }}
      gridRow={1}
      justifyContent="flex-start"
      justifySelf="start"
      marginLeft={{
        base: 0,
        md: -2, // avoid collision with other nav items
        lg: 0,
      }}
    >
      <LinkInternal
        bg="transparent"
        color="app.textHighlight"
        cursor={ !! atHomePage ? 'default' : 'pointer' }
        fontSize={navStyles.fontSize}
        paddingX={navStyles.paddingX}
        paddingY={navStyles.paddingY}
        textTransform="uppercase"
        to={routes.Home}
      >
        Harlan<ResponsiveHide display={{ ...responsiveText }}><Nbsp/>Lewis</ResponsiveHide>
      </LinkInternal>
    </Flex>
  );
};

AppNavBrand.propTypes = {
  atHomePage: PropTypes.bool,
  navStyles: PropTypes.object.isRequired,
};
