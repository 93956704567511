import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { routes } from 'lib/router/routes';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@chakra-ui/core';
import netlifyIdentity from 'netlify-identity-widget';
import { isLoggedIn } from 'lib/auth';
import { SwitchRoutes } from 'lib/router/SwitchRoutes';
import { theme } from 'lib/theme';
import { AppNav } from 'components/AppNav';
import { GridsetOverlay } from 'components/GridsetOverlay';
import { Box } from 'components/Layout';
import { ScrollToTop } from 'components/ScrollToTop';

import { polyfill as scrollPolyfill } from 'seamless-scroll-polyfill';

const helmetContext = {};
export const App = () => {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn);
  netlifyIdentity.on('login', (isLoggedIn) => {
    setLoggedIn(isLoggedIn);
    netlifyIdentity.close();
    window.location.href = routes.StrongDM;
  });
  netlifyIdentity.on('logout', (isLoggedIn) => setLoggedIn(isLoggedIn));
  const loggedInBool = !! loggedIn;
  scrollPolyfill();
  return (<>
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={ theme }>
        <GridsetOverlay />
        <Box
          color="app.text"
          fontFamily="base"
          fontSize="1rem"
          fontWeight="normal"
          minHeight="100vh"
          position="relative"
          zIndex="base" // place App low in the stack context to avoid conflict with JS overlay widgets
        >
          <Router>
            <ScrollToTop />
            <AppNav loggedIn={ loggedInBool } />
            <SwitchRoutes loggedIn={ loggedInBool } />
          </Router>
        </Box>
      </ThemeProvider>
    </HelmetProvider>
  </>);
};
