import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { Link } from 'react-router-dom';
import { removeTrailingSlash } from 'lib/helpers';
import { PseudoBox } from 'components/Layout';

export const styles = {
  paddingX: 1,
  paddingY: 1,
};


export const LinkInternal = ({ as, href, to, ...props }) => {
  const { location } = useContext(__RouterContext);
  const selected = !! to && removeTrailingSlash(location.pathname.toLowerCase()) === removeTrailingSlash(to.toLowerCase());

  const useHref = href || (!! to && !! selected);
  const useTo = !! to && ! selected;

  return (
    <PseudoBox
      _active={{
        bg: ! selected && 'linkInternal.bgActive',
        borderColor: ! selected && 'transparent',
        color: ! selected && 'linkInternal.colorActive',
      }}
      _hover={{
        bg: ! selected && 'linkInternal.bgHover',
        borderColor: ! selected && 'currentColor',
        color: ! selected && 'linkInternal.colorHover',
      }}
      alignItems="center"
      as={ as || (useTo ? Link : useHref ? 'a' : 'button')}
      bg={!! selected ? 'linkInternal.bgSelected' : 'linkInternal.bg' }
      border="1px solid transparent"
      borderRadius="md"
      color={!! selected ? 'linkInternal.colorSelected' : 'linkInternal.color' }
      display="inline-flex"
      fontFamily="header"
      fontSize="0.875em"
      fontWeight="bold"
      href={!! href ? href : !! selected ? '#root' : null}
      justifyContent="center"
      letterSpacing="loose"
      lineHeight={1}
      paddingX={styles.paddingX}
      paddingY={styles.paddingY}
      position="relative"
      to={!! useTo ? to : null}
      transition="all 0.3s"
      whiteSpace="nowrap"
      {...props}
    />
  );
};

LinkInternal.propTypes = {
  as: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
};
