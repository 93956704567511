import { jobDescriptions } from 'lib/jobDescriptions';

export function pageTitle(title) {
  let value = 'Harlan Lewis';
  if (! title) return value;

  if (jobDescriptions.map(jobs => jobs.org).includes(title)) {
    return value = `${value} | ${title} | Portfolio`;
  }

  return `${value} | ${title}`;
}
