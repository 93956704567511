import React from 'react';
import { theme } from 'lib/theme';
import { Gridset } from 'components/Gridset';
import { Quote } from 'components/Quote';


export const AudienceQuotes = () => (
  <Gridset
    alignItems="center"
    color="app.bg.base"
    paddingBottom={12}
    paddingTop={{
      base: 0,
      sm: 0,
      lg: 0
    }}
    paddingX={theme.space.viewportPaddingX}
  >
    <Quote
      // attribution="Data Scientist"
      // attributionColor="scheme.tertiary.base"
      color="scheme.tertiary.base"
      quote="How do ad interactions in the first two weeks affect 90-day retention?"
      springDelay={0}
    />
    <Quote
      // attribution="Product Manager"
      // attributionColor="scheme.tertiary.base"
      color="scheme.tertiary.base"
      quote="What causes organic conversion from free to premium?"
      springDelay={200}
    />
    <Quote
      // attribution="Growth Analytics"
      // attributionColor="scheme.tertiary.base"
      color="scheme.tertiary.base"
      quote="What leads to building a liked songs collection?"
      springDelay={400}
    />
  </Gridset>
);
