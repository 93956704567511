import React from 'react';
import { theme } from 'lib/theme';
import { ArticleBody } from 'components/ArticleBody';
import { SectionDivider } from 'components/Divider';
import { Box } from 'components/Layout';
import { SectionHeader } from 'components/SectionHeader';
import { spotifyInternalRoutes } from 'pages/Portfolio/Spotify/spotifyInternalRoutes';

import { LayerRow } from './LayerRow';


export const ProjectThoughtfulExecution = () => (<>
  <ArticleBody>
    <SectionDivider id={spotifyInternalRoutes.thoughtfulExecution} />
    <SectionHeader alignItems="flex-start" colorDecoration="scheme.secondary.dark" title="Hypothesis-Driven Design" />
    <p>I facilitated hypothesis-driven design as a near-term roadmap planning tool for the <em>Insights & Experiments</em> team, evaluating our solutions and results each sprint.</p>
    <p>Below is an example - the highlighted path below shows the end-to-end path for a new feature.</p>
  </ArticleBody>

  <SectionDivider height="half" />

  <Box paddingX={theme.space.viewportPaddingX}>
    <Box
      as="section"
      bg="dangerPalette.jet.base"
      paddingY={8}
    >
      <LayerRow
        description="What are we trying to accomplish?"
        level="goal"
        title="Goal"
      />
      <LayerRow
        description="What do we know?"
        level="data"
        title="Data & Insights"
      />
      <LayerRow
        description="What might address specific Data & Insights?"
        level="hypotheses"
        title="Hypotheses"
      />
      <LayerRow
        description="Specific actions to test our hypotheses."
        level="solutions"
        title="Solutions"
      />
      <LayerRow
        description="How will we know if the solution works?"
        level="evaluations"
        title="Evaluation Criteria"
      />
    </Box>
  </Box>
  <SectionDivider height="half" />
  <ArticleBody>
    <p>Benefits:</p>
    <ul>
      <li>Solutions are linked back to the impact we want to make on the customer’s experience or capabilites.</li>
      <li>The team has an agreed method for evaluating and socializing success.</li>
      <li>New ideas can be quickly contextualized relative to team priorities.</li>
      <li>Encouraged nuanced consideration of what makes a difference, and why.</li>
      <li>By facilitating this exercise for everyone - design, product, data, and engineering - we permeated the culture with an awareness of what mattered to the customer and what levers we had at our disposal.</li>
    </ul>
  </ArticleBody>
</>);

