import React from 'react';
import { Aside } from 'components/Aside';
import { Box } from 'components/Layout';


export const Callout = ({ ...props }) => (
  <Aside
    isPadded
    placement="wide"
  >
    <Box
      bg="white"
      borderRadius="lg"
      paddingX={10}
      paddingY={4}
      textAlign="left"
      {...props}
    />
  </Aside>
);
