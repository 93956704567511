import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { LAYOUT_SWITCH } from 'lib/mediaQuery';
import { ArticleImage } from 'components/ArticleImage';
import {
  Box,
  Grid,
} from 'components/Layout';
import {
  Subtitle,
} from 'components/Typography';


export const GalleryDivider = ({ ...props }) => (
  <Box bg="whiteAlpha.500" height="1px" marginY={24} {...props} />
);

export const GallerySection = ({ gallery, title }) => {
  const [intersectionObserverRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <Box
      ref={intersectionObserverRef}
      textAlign={{
        base: 'center',
        [LAYOUT_SWITCH]: 'start',
      }}
    >
      { !! title &&
        <Subtitle children={title} paddingBottom={8} />
      }
      <Grid
        children={ gallery.map((item, i) => (
          <ArticleImage
            isAnimated={false}
            key={i}
            triggerOnce={true}
            {...item}
          />
        ))}
        gridAutoFlow= "row dense"
        gridAutoRows= "minmax(15vmin, auto)"
        gridGap={4}
        gridTemplateColumns={{
          base: 'repeat(auto-fill, minmax(70vmin, 1fr))',
          [LAYOUT_SWITCH]: 'repeat(auto-fill, minmax(20vw, 1fr))',
        }}
        style={{
          opacity: !! inView ? 1 : 0,
          visibility: !! inView ? 'visible' : 'hidden',
        }}
        width="100%"
      />
    </Box>
  );
};

GallerySection.propTypes = {
  gallery: PropTypes.array.isRequired,
  smallImage: PropTypes.object,
  title: PropTypes.string.isRequired,
};
